const FormatHyperlinkText = ({ text }) => {
    const format = () => {
        var formattedText = text.split(/ /).map((word, index, elements) => {
            if(word.includes('\n')){
                var lineBroken = word.split('\n').map((line, index, elements) => {
                    if (line.startsWith('http://') || line.startsWith('https://')) {
                        return <><a className="is-actually-a-link" key={index} href={line} target="_blank" rel="noreferrer">{line} </a><br /></>
                    }
                    return (index == (elements.length - 1)) ? line + ' ' : line + '\n';
                })

                return lineBroken;
            }
            else {
                if (word.startsWith('http://') || word.startsWith('https://')) {
                    return <a className="is-actually-a-link" key={index} href={word} target="_blank" rel="noreferrer">{word} </a>
                }
                return (index == (elements.length - 1)) ? word : word + ' ';    
            }
        })

        return formattedText;
    }

    return (
        <>
            {format()}
        </>
    );
}

export default FormatHyperlinkText;