import axios from "axios";
import { useCallback, useEffect, useRef, useState } from "react";
import { environment } from "../environment";   
import { useTranslation } from "react-i18next";
import ReactLoading from "react-loading";
import { use } from "i18next";
import { MdClosedCaption } from "react-icons/md";
import { timeFormat } from "../utilities/helpers";

const VideoStreaming = (props) => {

    const [videoStream, setVideoStream] = useState(null);
    const [loading, setLoading] = useState(true);
    const [subtitles, setSubtitles] = useState(null);
    const [subtitlesUncued, setSubtitlesUncued] = useState(null);
    const [isEventListenerAdded, setIsEventListenerAdded] = useState(false);
    const [isSubtitlesOuterCueReady, setIsSubtitlesOuterCueReady] = useState(false);
    const [showCaptionBox, setShowCaptionBox] = useState(false);
    const [userClickedSubtitle, setUserClickedSubtitle] = useState(false);

    const {t} = useTranslation();

    function handleCueChange() {
        const track = document.getElementById('videoPlayer').textTracks[0];

        const activeCues = track.activeCues

        if(!isSubtitlesOuterCueReady){
            console.log("Setting subtitles uncued");

            var uncued = []

            for (const key in track.cues) {
                if (Object.hasOwnProperty.call(track.cues, key)) {
                    uncued.push({
                        id: track.cues[key].id,
                        startTime: track.cues[key].startTime,
                        endTime: track.cues[key].endTime,
                        text: track.cues[key].text,
                    });
                }
            }

            setSubtitlesUncued(uncued);

            setIsSubtitlesOuterCueReady(true);
        }

        if(!userClickedSubtitle){
            if (document.getElementById('activeSub')){
                const box = document.getElementById('captionBox');
                var pos = getRelativePos(document.getElementById('activeSub'));
                scrollTo(box, pos.top, 0.5)
            }
        }
        setUserClickedSubtitle(false);
    };

    function getRelativePos(elm){
        var pPos = elm.parentNode.getBoundingClientRect(), // parent pos
            cPos = elm.getBoundingClientRect(), // target pos
            pos = {
                top: cPos.top - pPos.top + elm.parentNode.scrollTop,
                right: cPos.right - pPos.right,
                bottom: cPos.bottom - pPos.bottom,
                left: cPos.left - pPos.left
            };
      
        return pos;
    }
          
    function scrollTo(element, to, duration, onDone) {
        var start = element.scrollTop,
            change = to - start,
            startTime = performance.now(),
            val, now, elapsed, t;
    
        function animateScroll(){
            now = performance.now();
            elapsed = (now - startTime)/1000;
            t = (elapsed/duration);
    
            element.scrollTop = start + change * easeInOutQuad(t);
    
            if( t < 1 )
                window.requestAnimationFrame(animateScroll);
            else
                onDone && onDone();
        };
    
        animateScroll();
    }

    function easeInOutQuad(t){ return t<.5 ? 2*t*t : -1+(4-2*t)*t };

    const videoTextElRef = useCallback((node) => {
        if(props.subtitles){
            console.log("Adding event listener");

            if (node && !isEventListenerAdded) {
                console.log("EVENT LISTENER ADDED");

                node?.textTracks[0].addEventListener('cuechange', handleCueChange);
                setIsEventListenerAdded(true);
            }
        }
    }, [isEventListenerAdded]);

    useEffect(() => {
        const fetchVideoStream = async () => {
            setLoading(true);
            try {
                const response = await axios.get(environment.baseAPI + props.url , {
                    responseType: 'blob',
                    headers: { 
                        Authorization: "Bearer " + localStorage.getItem('authtoken'),
                    },
                });

                const videoBlob = response.data;
                const reader = new FileReader();

                reader.onload = () => {
                    // Set the data URL as the video source
                    setVideoStream(reader.result);
                };

                // Read the blob as a data URL
                reader.readAsDataURL(videoBlob);
                setLoading(false);

            } catch (error) {
                setLoading(false);
                console.error('Error fetching video stream:', error);
            }
        };

        fetchVideoStream();
    }, []);

    useEffect(() => {
        if(props.saveTime){
            const interval = setInterval(() => {
                const videoPlayer = document.getElementById('videoPlayer');

                if(videoPlayer.currentTime != null && videoPlayer.currentTime > 0 && videoPlayer.paused != null && videoPlayer.paused === false && videoPlayer.ended != null && videoPlayer.ended === false){
                    axios.post(environment.baseAPI + '/library/savevideotime', {time: parseInt(videoPlayer.currentTime), id: props.id}, {
                        withCredentials: true,
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem('authtoken'),
                            Accept: 'application/json',
                        }
                    });
                } else if(videoPlayer.ended != null && videoPlayer.ended === true){
                    axios.post(environment.baseAPI + '/library/savevideotime', {time: 0, id: props.id}, {
                        withCredentials: true,
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem('authtoken'),
                            Accept: 'application/json',
                        }
                    });
                }
            }, 10000);

            return () => clearInterval(interval)
        }
    }, [props.saveTime, props.id])

    useEffect(() => {
        if(props.subtitles){
            axios.get(environment.baseAPI + '/library/subtitles?id=' + props.id , {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem('authtoken'),
                }
            }).then(response => {
                // Parse the WebVTT data
                const blob = new Blob([response.data], {type: 'text/vtt'});

                setSubtitles(URL.createObjectURL(blob));
                
            }).catch(error => {
                console.error('Error fetching subtitles:', error);
            });
        }

    }, [props.subtitles, props.id])

    const handleKeyStroke = (event) => {
        if (event.key === "ArrowLeft") {
            event.target.currentTime -= 10;
        }
        if (event.key === "ArrowRight") {
            event.target.currentTime += 10;
        }
    }

    const handleSubtitleClick = (subtitle) => {
        setUserClickedSubtitle(true);
        document.getElementById('videoPlayer').currentTime = subtitle.startTime
        const box = document.getElementById('captionBox');
        var pos = getRelativePos(document.getElementById('activeSub'));
        scrollTo(box, pos.top, 0.5)
    }

    return (
        <div>
            {loading ?
            <div className="w-full flex flex-row justify-center items-center h-full">
                <ReactLoading type={"spinningBubbles"} color={"#b7145a"} height={40} width={40} />
            </div>
            : 
            <div>
                {videoStream && (
                    <div>
                        <div className="relative group">
                            <video crossOrigin="use-credentials" id="videoPlayer" controls className="rounded-lg relative z-0" tabIndex={"0"} onKeyDown={(e) => handleKeyStroke(e)} ref={videoTextElRef} >
                                <source src={videoStream + "#t=" + props.ts} type="video/mp4" />
                                <p>{t('app.format.utils.videounsupportedbrowser')}</p>
                                {
                                    subtitles && <track src={subtitles} kind="captions" label="Malay" srcLang="ms" default={true} mode="showing" />
                                }
                            </video>
                            <div className="absolute z-20 top-0 left-0 opacity-0 transition-all group-hover:opacity-100">
                                <h1 className="text-md p-3 font-light text-gray-300">{props.title}</h1>
                            </div>
                            <div className="rounded-lg absolute z-10 top-0 left-0 w-full h-1/3 opacity-0 transition-all group-hover:opacity-50" style={{backgroundImage: "linear-gradient(to top, rgba(0,0,0,0), rgba(0,0,0,1))"}}></div>
                            
                        </div>
                        {(props.subtitles && subtitles && subtitlesUncued) && 
                        <div className="pt-5">
                            <div className="flex flex-row justify-between">
                                <h1>{t('app.format.video.has_captions')}</h1>
                                <MdClosedCaption className="cursor-pointer" onClick={() => setShowCaptionBox(!showCaptionBox)} />
                            </div>
                            <div id="captionBox" className="pre-wrap whitespace-pre-line h-48 overflow-auto nobar" hidden={!showCaptionBox}>
                                { (subtitlesUncued && document.getElementById('videoPlayer') && document.getElementById('videoPlayer').currentTime) && subtitlesUncued.map((subtitle, index) => {
                                    var videoTrack = document.getElementById('videoPlayer');
                                    var isCurrentSub = (videoTrack.currentTime >= subtitle.startTime && videoTrack.currentTime < subtitle.endTime)
                                    return (
                                        <div id={(isCurrentSub ? "activeSub" : "")} key={index} className={"p-2 font-light cursor-pointer rounded hover:bg-slate-100 dark:hover:bg-slate-700 " + (isCurrentSub ? "bg-slate-200 dark:bg-slate-600" : "text-slate-500")} onClick={() => handleSubtitleClick(subtitle)}>
                                            <p>{timeFormat(subtitle.startTime)}</p>
                                            <p>{subtitle.text}</p>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                        }
                    </div>
                )}
            </div>
            }
        </div>
    );
}

export default VideoStreaming;