import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { environment } from '../../environment';
import ReactLoading from 'react-loading';
import { timestampToHourMinute, getShortSize } from '../../utilities/helpers';
import Echo from 'laravel-echo';
import { use } from 'i18next';
import FormatHyperlinkText from '../FormatHyperlinkText';
import InfiniteScroll from 'react-infinite-scroll-component';
import { MdCancel, MdFileUpload, MdSend } from 'react-icons/md';
import { checkFlaggedWords, flaggedWords } from '../../utilities/flaggedwords';

const Messages = ({ chat }) => {
    const [messages, setMessages] = useState([]);
    const [loading, setLoading] = useState(true);
    const [user, setUser] = useState({});
    const [newMessage, setNewMessage] = useState('');
    const [notificationsEnabled, enableNotifications] = useState(false);
    const [bottomScroll, setBottomScroll] = useState(0);
    const [hasImage, setHasImage] = useState(false);
    const [loadingMore, setLoadingMore] = useState(false);

    const fileTypes = ["JPG", "PNG", "GIF"]

    const [file, setFile] = useState(null);
    const handleAttachmentChange = (file) => {
        setFile(file);

        //preview if image
        if(fileTypes.includes(file.name.split('.').pop().toUpperCase())){
            setHasImage(true);
            const reader = new FileReader();
            reader.onload = function(e) {
                document.getElementById('attachmentPreview').src = e.target.result;
            }
            document.getElementById('attachmentPreview').src = reader.readAsDataURL(file);
        }
        else {
            setHasImage(false);
        }
    }

    // const audio = new Audio('/sounds/messeeji.mp3');
    // audio.volume = 0.1;

    const [page, setPage] = useState(1);
    

    const nav = useNavigate();

    useEffect(() => {
        axios.get(environment.baseAPI + '/auth/me', {withCredentials: true, headers: {
            Authorization: "Bearer " + localStorage.getItem('authtoken')}
        }).then(res => {
            setUser(res.data.user);
        }).catch(error => {
            if (error.response.status === 401) {
                nav('/login');
            }
        });
    }, []);

    useEffect(() => {
        fetchMessages();
    }, [chat]);

    const fetchMessages = () => {
        setLoading(true);
        axios.get(environment.baseAPI + '/messages/get?receiver_id=' + chat.id + '&page=' + page, {
            withCredentials: true, 
            headers: {
                Authorization: "Bearer " + localStorage.getItem('authtoken'),
                Accept: "application/json",
            }
        }).then(res => {
            // setMessages([...messages, res.data.data.reverse()]);
            var oldMessages = messages;
            // var newMessages = res.data.data.reverse();
            var newMessages = res.data.data;

            setMessages([...oldMessages, ...newMessages])
            setLoading(false);
            if(res.data.data.length > 0){
                setPage(page + 1);
            }
        }).catch(error => {
            if (error.response.status === 401) {
                nav('/login');
            }
        });
    }

    useEffect(() => {
        if (user.id === undefined) return;
        window.Echo.private('user.' + user.id)
            .listen('.new-message-was-sent', (e) => {
                if (e.message.sender_id === chat.id) {
                    // if(notificationsEnabled){
                    //     audio.volume = 0.1;
                    //     audio.play();
                    // }
                    
                    setMessages([e.message, ...messages]);
                }
            });
    }, [user, chat, messages]);
    
    const handleSendMessage = (e) => {
        e.preventDefault();

        if(newMessage === '' || newMessage === ' ') return;

        if(checkFlaggedWords(newMessage)){
            alert('Your message contains inappropriate words.');

            return;
        }

        setNewMessage('');

        var messageData = new FormData();
        messageData.append('receiver_id', chat.id);
        messageData.append('message', newMessage);
        if(file !== null){
            messageData.append('attachment', file);
        }

        axios.post(environment.baseAPI + '/messages/send', messageData, {
            withCredentials: true,
            headers: {
                Authorization: "Bearer " + localStorage.getItem('authtoken'),
                Accept: "application/json",
                ContentType: "multipart/form-data"
            }
        }).then(res => {
            setMessages([res.data, ...messages]);
            // messages.push(res.data);

            document.getElementById('newMessageInput').value = '';
            setBottomScroll(bottomScroll + 1);
        }).catch(error => {
            if (error.response.status === 401) {
                nav('/login');
            }
        });

        setFile(null);
        setHasImage(false);
    }   

    const messagesEndRef = useRef(null);

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' })
    }

    useEffect(() => {
        if(!loadingMore) {
            scrollToBottom();
        }
        setLoadingMore(false);
    }, [messages, bottomScroll]);

    const cancelFile = () => {
        setFile(null);
        setHasImage(false);
    }

    const messageForm = useRef(null);

    const checkEnterKey = (e) => {
        if(e.keyCode == 13 && e.shiftKey == false) {
            messageForm.current.requestSubmit();
        }
    }

    var currentDay = '';

    const isImage = (url) => {
        return(url.match(/\.(jpeg|jpg|gif|png)$/) != null);
    }   

    return (
        <div className='relative'>
            <div className=''>
                <div className='overflow-y-scroll h-[calc(100vh-10rem)] nobar'>
                    { (loading) ?
                    <div className='w-full h-full flex flex-row justify-center items-center'> 
                        <ReactLoading type={"spinningBubbles"} color={"#b7145a"} height={40} width={40} />
                    </div>
                    :
                    <div className='mb-auto max-h-full'>
                        <InfiniteScroll
                            dataLength={messages.length}
                            next={fetchMessages}
                            style={{ display: 'flex', flexDirection: 'column-reverse', gap: 10}}
                            hasMore={true}
                            inverse={true}
                            scrollableTarget="messageContainer"
                            loader={<ReactLoading type={"spinningBubbles"} color={"#b7145a"} height={40} width={40} />}
                            endMessage={<p className='text-center'>No more messages</p>}
                        >
                            {/* <ul id="messageContainer" className='flex flex-col gap-3 max-h-full overflow-y-auto relative nobar'> */}
                                <div ref={messagesEndRef} />
                                
                                {
                                messages.map((message, index) => {
                                    if(currentDay ===  ''){
                                        currentDay = new Date(message.created_at).toDateString();
                                    }
                                    else if(currentDay !== new Date(message.created_at).toDateString()){
                                        if(messages[index-1] !== undefined){
                                            currentDay = new Date(messages[index-1].created_at).toDateString();
                                        }

                                        if(currentDay === new Date().toDateString()){
                                            currentDay = 'Today';
                                        }
                                        return (
                                            <>
                                            <div className='w-full flex flex-row justify-center items-center gap-5'>
                                                <div className='w-1/3 bg-slate-300 dark:bg-slate-600 h-0.5 rounded-lg'></div>
                                                <p className='text-slate-500 dark:text-slate-40 text-sm'>{currentDay}</p>
                                                <div className='w-1/3 bg-slate-300 dark:bg-slate-600 h-0.5 rounded-lg'></div>
                                            </div>
                                            <div key={message.id} className={"w-fit px-5 py-2 rounded-xl max-w-[75%] " + ((message.sender_id === user.id) ? "ml-auto bg-blue-300 dark:bg-blue-400" : "bg-slate-300 dark:bg-slate-600")}>
                                                {
                                                (message.attachments && message.attachments.length > 0 && isImage(message.attachments[0].attachment)) ?
                                                <div className='mb-5'>
                                                    <img src={message.attachments[0].attachment} className="w-full h-auto object-cover rounded-xl" />
                                                </div>
                                                : ''
                                                }
                                                <div className="flex flex-row gap-3 text-left items-start">
                                                    <div className='text-left flex flex-row items-end gap-3 justify-between'>
                                                        <p className='text-sm font-light whitespace-pre-line break-words'>{message.message && <FormatHyperlinkText text={message.message} />} </p>
                                                        <p className='text-xs font-light text-slate-500 flex-shrink-0'>{timestampToHourMinute(message.created_at)}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            </>
                                        )
                                    }
                                    return <div key={message.id} className={"w-fit px-5 py-2 rounded-xl max-w-[75%] " + ((message.sender_id === user.id) ? "ml-auto bg-blue-300 dark:bg-blue-400" : "bg-slate-300 dark:bg-slate-600")}>
                                        {
                                        (message.attachments && message.attachments.length > 0 && isImage(message.attachments[0].attachment)) ?
                                        <div className='mb-5 mt-4'>
                                            <img src={message.attachments[0].attachment} className="object-cover rounded-xl" />
                                        </div>
                                        : ''
                                        }
                                        <div className="flex flex-row gap-3 text-left items-start">
                                            <div className='text-left flex flex-row items-end gap-3 justify-between'>
                                                <p className='text-sm font-light whitespace-pre-line break-words'>{message.message && <FormatHyperlinkText text={message.message} />} </p>
                                                <p className='text-xs font-light text-slate-500 flex-shrink-0'>{timestampToHourMinute(message.created_at)}</p>
                                            </div>
                                        </div>
                                    </div>
                                })}

                                <div className='w-full'>
                                    <button onClick={() => {fetchMessages(); setLoadingMore(true)}} className="w-full text-sm text-blue-500 p-3 bg-transparent">Load More</button>
                                </div>
                            {/* </ul> */}
                        </InfiniteScroll>
                    </div>
                    }
                </div>
                
            </div>
            <div className='p-2 rounded-lg absolute bottom-14 bg-slate-300' hidden={!hasImage}>
                <div className='flex flex-row gap-5 items-center'>
                    <img id="attachmentPreview" className="w-10 h-10 rounded-xl object-cover" hidden={!hasImage} />                    
                    <div>
                        <p>File Attachment</p>
                        {file && <p className='text-xs'>{file.name}  •  <span>{getShortSize(file.size)}</span></p>}

                    </div>
                    <MdCancel onClick={() => cancelFile()} size={30} className="cursor-pointer" />
                </div>
            </div>
            <div className='pt-3'>
                <form className='flex flex-row gap-3' onSubmit={(e) => handleSendMessage(e)} ref={messageForm}>
                    <div>
                        <button type="button" className="p-3 bg-blue-300 dark:bg-blue-400 rounded-xl h-full" onClick={() => document.getElementById('attachmentInput').click()}><MdFileUpload /></button>
                        <input id="attachmentInput" type="file" className="hidden" accept="image/*" onChange={(e) => handleAttachmentChange(e.target.files[0])} />
                    </div>
                    <textarea onKeyDown={(e) => checkEnterKey(e)} autoFocus={true} autoComplete='off' onFocus={() => enableNotifications(true)} id='newMessageInput' type="text" className="w-full resize-none p-3 rounded-xl text-black" rows={1} placeholder="Type a message..." onChange={(e) => setNewMessage(e.target.value)} />
                    <button className="p-3 bg-blue-300 dark:bg-blue-400 rounded-xl"><MdSend/></button>
                </form>
            </div>
        </div>
    );
}

export default Messages;