import axios from "axios";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { environment } from "../../environment";
import ReactLoading from "react-loading";
import { Link } from "react-router-dom";
import { timeAgo, timeFormat } from "../../utilities/helpers";

const Nexus = () => {
    const [videos, setVideos] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        document.title = "Nexus - Puspakata";

        setLoading(true);
        axios.get(environment.baseAPI + '/library/videos', {
            withCredentials: true,
            headers: {
                Authorization: "Bearer " + localStorage.getItem('authtoken'),
                Accept: 'application/json',
            }
        }).then(response => {
            setVideos(response.data);
            setLoading(false);
        }).catch(error => {
            if (error.response && error.response.status === 401) {
                window.location.href = '/login';
            }
        });
    }, []); 

    const {t} = useTranslation();

    return (
        <div className="text-left p-10">
            {
            (loading) ?
            <div className="w-full h-full flex flex-row justify-center items-center"> 
                <ReactLoading type={"spinningBubbles"} color={"#b7145a"} height={40} width={40} />
            </div>
            :
            <div>
                <h1 className="text-3xl mb-5">{t('app.format.nexus.header')}</h1>
                <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
                    {videos.map((video, index) => {
                        return (
                            <Link key={index} to={"/nexus/watch/" + video.hash_id + ((video.timestamp) ? "?ts=" + video.timestamp : "") }>
                                <div key={index} className="">
                                    <div className="w-full h-fit aspect-video relative rounded-lg">
                                        <img className="w-full aspect-video object-cover rounded-lg relative" src={video.thumbnail_path} />
                                        {
                                            video.duration != null &&
                                            <div className="absolute z-10 bottom-0 left-0 w-full">
                                                <div className=" w-fit text-xs bg-black bg-opacity-50 text-white p-1 rounded-lg">{timeFormat(video.duration)}</div>
                                                { video.timestamp && (video.timestamp > 0) ?
                                                <div className="w-full relative">
                                                    <div className="absolute bottom-0 z-10 h-1 bg-[#b7145a]" style={{width: String(Math.round((video.timestamp / video.duration) * 100)) + "%"}}></div>
                                                    <div className="absolute bottom-0 left-0 w-full h-1 bg-slate-100"></div>
                                                </div>
                                                :
                                                ""
                                                }
                                            </div>
                                        }
                                    </div>
                                    <div className="py-5 flex flex-row gap-3">
                                        <div className="h-fit flex-shrink-0">
                                            { video.users && video.users.avatar != null ?
                                                <img id="pfp" src={video.users.avatar} alt="" className="rounded-full pfp w-[40px] h-[40px]"
                                                    data-dz-thumbnail="data-dz-thumbnail" />
                                            :
                                                <img id="pfp"
                                                    src={video.users &&  "https://ui-avatars.com/api/?background=D6D6D6&bold=true&name=" + video.users.name} className="rounded-full pfp"
                                                    width="40" alt="" data-dz-thumbnail="data-dz-thumbnail" />
                                            }
                                        </div>
                                        <div>
                                            <p className="line-clamp-1 text-xs text-slate-500 bg-slate-200 w-fit px-1 rounded">{video.courses && video.courses.name}</p>
                                            <h1 className="line-clamp-1 text-sm">{video.title}</h1>
                                            <p className="line-clamp-1 text-xs text-slate-500">{video.users.name}</p>
                                            <div className="flex flex-row">
                                                <p className="line-clamp-1 text-xs text-slate-500">{Intl.NumberFormat().format(video.views) + (video.views == 1 ? " view" : " views")}</p>
                                                <span className="mx-2 text-xs text-slate-500">&middot;</span>
                                                <p className="line-clamp-1 text-xs text-slate-500">{video.created_at && timeAgo(video.created_at)}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        );
                    })}
                </div>
            </div>
            }
        </div>
    );
}

export default Nexus;