import axios from "axios";
import { useEffect, useState } from "react";
import { environment } from "../environment";
import { useNavigate, Link } from "react-router-dom";
import ReactLoading from "react-loading";
import { useTranslation } from "react-i18next";
import { MdBook } from "react-icons/md";
import FormatHyperlinkText from '../components/FormatHyperlinkText';
import { timeAgo, timeFormat } from "../utilities/helpers";

const Home = () => {
    const { t } = useTranslation();
    const nav = useNavigate();
    const [user, setUser] = useState(null);
    const [courses, setCourses] = useState([]);
    const [coursesLoading, setCoursesLoading] = useState(true);
    const [continueWatching, setContinueWatching] = useState([]);
    const [continueWatchingLoading, setContinueWatchingLoading] = useState(true);

    useEffect(() => {
        document.title = "Home - Puspakata";    
        axios.get(environment.baseAPIRef + '/sanctum/csrf-cookie').then(response => {
            axios.get(environment.baseAPI + '/auth/me', {withCredentials: true, headers: {Authorization: "Bearer " + localStorage.getItem('authtoken')}}).then(res => {
                setUser(res.data.user);
                
            }).catch(error => {
                nav('/login');
            });
        });

        setSearchLoading(true);

        axios.get(environment.baseAPI + '/users/search?q=', {
            withCredentials: true, 
            headers: {
                Authorization: "Bearer " + localStorage.getItem('authtoken'),
                Accept: 'application/json',
            }
        }).then(response => {
            setSearchUsers(response.data);

            setSearchLoading(false);
        }).catch(error => {
            if (error.response.status === 401) {
                nav('/login');
            }
        });

        axios.get(environment.baseAPI + '/courses/simplesearch?q=' + '&limit=5', {
            withCredentials: true, 
            headers: {
                Authorization: "Bearer " + localStorage.getItem('authtoken'),
                Accept: 'application/json',
            }
        }).then(response => {
            setSearchCourses(response.data);
            setSearchLoading(false);
        }).catch(error => {
            if (error.response.status === 401) {
                nav('/login');
            }
        });

        setCoursesLoading(true);
        axios.get(environment.baseAPI + '/courses/mycourses', {
            withCredentials: true, 
            headers: {
                Authorization: "Bearer " + localStorage.getItem('authtoken'),
                Accept: 'application/json',
            }
        }).then(res => {
            setCourses(res.data);
            setCoursesLoading(false);
        }).catch(error => {
            if (error.response.status === 401) {
                nav('/login');
            }
        });

        setContinueWatchingLoading(true);
        axios.get(environment.baseAPI + '/library/continuewatching', {
            withCredentials: true, 
            headers: {
                Authorization: "Bearer " + localStorage.getItem('authtoken'),
                Accept: 'application/json',
            }
        }).then(res => {
            setContinueWatching(res.data);
            setContinueWatchingLoading(false);
        }).catch(error => {
            if (error.response.status === 401) {
                nav('/login');
            }
        });
    }, []);

    const [searchLoading, setSearchLoading] = useState(false);
    const [searchUsers, setSearchUsers] = useState([]);
    const [searchCourses, setSearchCourses] = useState([]);

    const controller = new AbortController();

    const handleSearch = (query) => {
        if(searchLoading) {
            controller.abort();
            setSearchLoading(false);
        }

        setSearchLoading(true);

        axios.get(environment.baseAPI + '/users/search?q=' + query, {
            signal: controller.signal,
            withCredentials: true, 
            headers: {
                Authorization: "Bearer " + localStorage.getItem('authtoken'),
                Accept: 'application/json',
            }
        }).then(response => {
            setSearchUsers(response.data);
            setSearchLoading(false);
        }).catch(error => {
            if (error && error.response && error.response.status === 401) {
                nav('/login');
            }
        });

        axios.get(environment.baseAPI + '/courses/simplesearch?q=' + query + '&limit=5', {
            signal: controller.signal,
            withCredentials: true, 
            headers: {
                Authorization: "Bearer " + localStorage.getItem('authtoken'),
                Accept: 'application/json',
            }
        }).then(response => {
            setSearchCourses(response.data);
            setSearchLoading(false);
        }).catch(error => {
            if (error && error.response && error.response.status === 401) {
                nav('/login');
            }
        });
    }

    const getTimedGreeting = () => {
        const time = new Date().getHours();
        if (time < 12) {
            return t('app.format.greeting1');
        } else if (time < 16) {
            return t('app.format.greeting2');
        } else {
            return t('app.format.greeting3');
        }
    }

    const [showSearchResults, setShowSearchResults] = useState(false);

    const handleSearchRedir = (route) => {
        nav(route);
    }   

    return (
        <div className="p-8 mt-3 text-left w-full">
            <div className="mb-5 flex sm:flex-row sm:items-start sm:justify-between flex-col gap-5">
                {user ? <h1 className="text-3xl">{ getTimedGreeting() }, {(user.name).replace(/ .*/,'')}!</h1> : <ReactLoading type={"bars"} color={"#b7145a"} height={40} width={40} />}
                <div className='flex flex-row gap-5 relative sm:w-1/2 w-full' tabIndex={0} onFocus={() => setShowSearchResults(true)} onBlur={() => setShowSearchResults(false)}>
                    <div className="w-full">
                        <input className="text-slate-700 shadow-lg rounded-full p-1 px-5 w-full" placeholder={t('app.format.search')} type="text" onInput={(e) => handleSearch(e.target.value)} on />
                        <div className="w-full" style={{ display: showSearchResults ? "block" : "none"}}>
                            <div className="max-h-96 overflow-y-scroll absolute z-50 w-full bg-white dark:bg-slate-700 shadow-lg rounded-lg py-2">
                                { searchLoading ? 
                                    <div className="py-3 w-full flex justify-center">
                                        <ReactLoading type={"bars"} color={"#b7145a"} height={30} width={30} />
                                    </div>
                                    :
                                    <>
                                        { 
                                            (searchUsers.length == 0 && searchCourses.length == 0) ?
                                            <div className="w-full p-3 text-center">
                                                <p className="h-fit text-slate-500">{t('app.format.no_search_results')}</p>
                                            </div>
                                                :
                                            <>
                                                { searchUsers.length > 0 ? 
                                                <div className="">
                                                    { searchUsers.map(user => {
                                                        return (
                                                            <div className="w-full dark:hover:bg-slate-400 hover:bg-slate-200 transition-colors py-3 px-5">
                                                                <div onClick={() => handleSearchRedir('/profile/' + user.username)} className="cursor-pointer">
                                                                    <div className="flex flex-row items-center gap-5">
                                                                        <div className="">
                                                                            { user.avatar != null ?
                                                                                <img id="pfp" src={user.avatar} width="40" alt="" className="rounded-full"
                                                                                    data-dz-thumbnail="data-dz-thumbnail" />
                                                                            :
                                                                                <img id="pfp"
                                                                                    src={"https://ui-avatars.com/api/?background=D6D6D6&bold=true&name=" + user.name} className="rounded-full"
                                                                                    width="40" alt="" data-dz-thumbnail="data-dz-thumbnail" />
                                                                            }
                                                                        </div>
                                                                        <div>
                                                                            <h1 className="text-md">{user.name}</h1>
                                                                            <p className="font-light text-sm">@{user.username}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    }    
                                                    )}
                                                </div>
                                                :
                                                ""
                                                }
                                                {
                                                    searchCourses.length > 0 ? 
                                                    <div className="">
                                                        { searchCourses.map(course => {
                                                            return (
                                                                <div className="w-full dark:hover:bg-slate-400 hover:bg-slate-200 transition-colors py-3 px-5">
                                                                    <div onClick={() => handleSearchRedir('/courses/' + course.hash_id)} className="cursor-pointer">
                                                                        <div className="flex flex-row items-center gap-5">
                                                                            <div>
                                                                                <MdBook />
                                                                            </div>
                                                                            <div>
                                                                                <h1 className="text-md">{course.name}</h1>
                                                                                <p className="font-light text-sm">{course.short_description}</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })}
                                                    </div>
                                                    :
                                                    ""
                                                }
                                            </>
                                        }
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <div className="sm:grid sm:grid-cols-3 sm:gap-3">
                <div className="col-span-1 flex flex-col gap-5 mb-5 sm:mb-0">
                    <div className="bg-white dark:bg-slate-700 shadow-lg rounded-lg p-5 w-full">
                        <h1 className="text-xl">{t('app.format.home.courses')}</h1>
                        <p className="font-light text-sm mb-5">{t('app.format.home.courses_description')}</p>
                        <div className="flex flex-col gap-5">
                            {
                                coursesLoading ? <ReactLoading type={"bars"} color={"#b7145a"} height={40} width={40} />
                                :
                                <div>
                                    {
                                        (courses.length === 0)
                                        ?
                                        <div>
                                            <img src="/illustrations/books.png" className="w-1/2 mx-auto mb-5" />
                                            <p className="text-slate-500 text-center mb-5">{t('app.format.home.no_courses')}</p>
                                            <button className="btn-cta w-full" onClick={() => { nav('/courses') }}>{t('app.format.home.find_courses')}</button>
                                        </div>
                                        :
                                        <div>
                                        {
                                        courses.map(course => {
                                            return (
                                                <div className="cursor-pointer" onClick={() => { nav('/courses/' + course.hash_id) }}>
                                                    <div className="flex flex-row items-center gap-3">
                                                        <h1 className="text-lg text-yellow-500">80%</h1>
                                                        <div className="bg-slate-200 w-full">
                                                            <div className="h-1 bg-yellow-500" style={{ width: "80%"}}></div>
                                                        </div>
                                                    </div>
                                                    <p className="text-lg font-light">{course.name}</p>
                                                </div>
                                            )
                                        })}
                                        </div>
                                    }
                                </div>
                            }
                        </div>
                    </div>
                    <div className="bg-white dark:bg-slate-700 shadow-lg rounded-lg p-5 w-full">
                        <h1 className="text-xl">{t('app.format.home.messages')}</h1>
                        <p className="font-light text-sm mb-5">{t('app.format.home.messages_description')}</p>
                        {
                            continueWatchingLoading ? <ReactLoading type={"bars"} color={"#b7145a"} height={40} width={40} />
                            :
                            <div>
                                {
                                    (0 === 0)
                                    ?
                                    <div>
                                        <img src="/illustrations/smartphone.png" className="w-1/4 mx-auto mb-5" />
                                        <p className="text-slate-500 text-center mb-5">{t('app.format.home.no_messages')}</p>
                                    </div>
                                    :
                                    <div>
                                    </div>
                                }
                            </div>
                        }
                    </div>
                </div>
                <div className="col-span-2 flex flex-col gap-5">
                    <div className="bg-white dark:bg-slate-700 shadow-lg rounded-lg p-5 w-full">
                        <h1 className="text-xl">{t('app.format.home.continue_watching')}</h1>
                        <p className="font-light text-sm mb-5">{t('app.format.home.continue_watching_description')}</p>
                        {
                            continueWatchingLoading ? <ReactLoading type={"bars"} color={"#b7145a"} height={40} width={40} />
                            :
                            <div>
                                {
                                    (continueWatching.length === 0)
                                    ?
                                    <div>
                                        <img src="/illustrations/online-class.png" className="w-1/4 mx-auto mb-5" />
                                        <p className="text-slate-500 text-center mb-5">{t('app.format.home.no_continue_watching')}</p>
                                    </div>
                                    :
                                    <div>
                                        <div className="flex flex-col gap-5">
                                        {continueWatching.map((video, index) => {
                                            return (
                                                <>
                                                {video.content && <Link key={index} to={"/nexus/watch/" + video.content.hash_id + ((video.content_timestamp) ? "?ts=" + video.content_timestamp : "") }>
                                                    <div key={index} className="grid grid-cols-6 gap-5">
                                                        <div className="col-span-2 w-full h-fit aspect-video relative rounded-lg">
                                                            <img className="w-full aspect-video object-cover rounded-lg relative" src={video.content.thumbnail_path} />
                                                            {
                                                                video.content.duration != null &&
                                                                <div className="absolute z-10 bottom-0 left-0 w-full">
                                                                    <div className=" w-fit text-xs bg-black bg-opacity-50 text-white p-1 rounded-lg">{timeFormat(video.content.duration)}</div>
                                                                    { video.content_timestamp && (video.content_timestamp > 0) ?
                                                                    <div className="w-full relative">
                                                                        <div className="absolute bottom-0 z-10 h-1 bg-[#b7145a] " style={{width: String(Math.round((video.content_timestamp / video.content.duration) * 100)) + "%"}}></div>
                                                                        <div className="absolute bottom-0 left-0 w-full h-1 bg-slate-100"></div>
                                                                    </div>
                                                                    :
                                                                    ""
                                                                    }
                                                                </div>
                                                            }
                                                        </div>
                                                        <div className="col-span-4 flex flex-row gap-3">
                                                            <div className="h-fit flex-shrink-0">
                                                                { video.content.users && video.content.users.avatar != null ?
                                                                    <img id="pfp" src={video.content.users.avatar} alt="" className="rounded-full pfp w-[40px] h-[40px]"
                                                                        data-dz-thumbnail="data-dz-thumbnail" />
                                                                :
                                                                    <img id="pfp"
                                                                        src={video.content.users &&  "https://ui-avatars.com/api/?background=D6D6D6&bold=true&name=" + video.content.users.name} className="rounded-full pfp"
                                                                        width="40" alt="" data-dz-thumbnail="data-dz-thumbnail" />
                                                                }
                                                            </div>
                                                            <div>
                                                                <p className="line-clamp-1 text-xs text-slate-500 bg-slate-200 w-fit px-1 rounded">{video.content.courses && video.content.courses.name}</p>
                                                                <h1 className="line-clamp-1 text-md">{video.content.title}</h1>
                                                                <p className="line-clamp-1 text-xs text-slate-500">{video.content.users.name}</p>
                                                                <div className="flex flex-row">
                                                                    <p className="line-clamp-1 text-xs text-slate-500">{Intl.NumberFormat().format(video.content.views) + (video.content.views == 1 ? " view" : " views")}</p>
                                                                    <span className="mx-2 text-xs text-slate-500">&middot;</span>
                                                                    <p className="line-clamp-1 text-xs text-slate-500">{video.created_at && timeAgo(video.content.created_at)}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Link>}
                                                </>
                                            );
                                        })}
                                        </div>
                                    </div>
                                }
                            </div>
                        }
                    </div>
                    <div className="bg-white dark:bg-slate-700 shadow-lg rounded-lg p-5 w-full">
                        <h1 className="text-xl">{t('app.format.home.forums')}</h1>
                        <p className="font-light text-sm">{t('app.format.home.forums_description')}</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Home;