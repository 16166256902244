import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import { environment } from '../../environment';
import { useTranslation } from 'react-i18next';
import { MdAdd } from 'react-icons/md';
import LessonCreateDialog from '../../components/management/LessonCreateDialog';

const ManageCourse = () => {
    const { courseId } = useParams();
    const [course, setCourse] = useState(null);
    const [reload, setReload] = useState(false);
    const [loading, setLoading] = useState(true);
    const [user, setUser] = useState(null);

    const {t} = useTranslation();

    useEffect(() => {
        document.title = "Manage Course - Puspakata";
        fetchCourse();
        fetchUser();
    }, [])

    useEffect(() => {
        if(course != null && user != null && course.instructor_id !== user.id) {
            window.location.href = '/manage/courses';
        }
    }, [course, user])

    const fetchCourse = () => {
        setLoading(true);
        
        axios.get(environment.baseAPI + '/courses/show?id=' + courseId , {
            withCredentials: true, 
            headers: {
                Authorization: "Bearer " + localStorage.getItem('authtoken'),
                Accept: 'application/json',
            }
        }).then(response => {
            setCourse(response.data.course);
            setLoading(false);
        }
        ).catch(error => {
            if (error.response.status === 401) {
                window.location.href = '/login';
            }
        });
    }

    const fetchUser = () => {
        axios.get(environment.baseAPI + '/auth/me', {
            withCredentials: true, 
            headers: {
                Authorization: "Bearer " + localStorage.getItem('authtoken'),
                Accept: 'application/json',
            }
        }).then(res => {
            setUser(res.data.user);
        }).catch(error => {
            if (error.response.status === 401) {
                window.location.href = '/login';
            }
        });
    }

    return (
        <div className='text-left p-10'>
            <h1 className='text-xl'>Manage {course && course.name}</h1>
            <div className='py-5 grid-cols-2 grid gap-5'>
                <div className='col-span-1'>
                    <div className='flex flex-row justify-between mb-5'>
                        <h2 className=''>Lessons</h2>
                        <LessonCreateDialog />
                    </div>
                    <div className='flex flex-col gap-5'>
                        {course && course.lessons.map(lesson => (
                            <Link key={lesson.id} to={`/manage/courses/${course.hash_id}/lessons/${lesson.hash_id}`}>
                                <div key={lesson.id} className='p-5 bg-slate-100 dark:bg-slate-700 rounded-lg hover:bg-slate-200 dark:hover:bg-slate-600'>
                                    <div>
                                        <h1 className='text-xs text-slate-500'>{lesson.created_at}</h1>
                                        <h1>{lesson.name}</h1>
                                        <p className='font-light text-xs'>{lesson.description}</p>
                                    </div>
                                </div>
                            </Link>
                        ))}          
                    </div>
                </div>
                <div>
                    <h2 className='mb-5'>Content</h2>
                    <div>
                        
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ManageCourse;