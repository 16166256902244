export const timeFormat = (s) => {return(s-(s%=60))/60+(9<s?':':':0')+s}

export const timeAgo = (timestamp) => {
    const date = new Date(timestamp);
    const now = new Date();
    const diffMilliseconds = now - date;

    // Define the time intervals in milliseconds
    const intervals = {
        year: 1000 * 60 * 60 * 24 * 365,
        month: 1000 * 60 * 60 * 24 * 30,
        week: 1000 * 60 * 60 * 24 * 7,
        day: 1000 * 60 * 60 * 24,
        hour: 1000 * 60 * 60,
        minute: 1000 * 60
    };

    // Calculate the difference in each time interval
    for (let interval in intervals) {
        const value = Math.floor(diffMilliseconds / intervals[interval]);
        if (value >= 1) {
            return value === 1 ? `1 ${interval} ago` : `${value} ${interval}s ago`;
        }
    }

    // If the difference is less than a minute, return 'just now'
    return 'just now';
}

export const timestampToHourMinute = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
}

export const getShortSize = (size) => {
    const units = ['B', 'kB', 'MB', 'GB', 'TB'];
    let unitIndex = 0;
    while (size > 1024) {
        size = size / 1024;
        unitIndex++;
    }
    return size.toFixed(2) + ' ' + units[unitIndex];
}