import axios from "axios";
import { useEffect, useState } from "react";
import { environment } from "../../environment";
import { useNavigate } from "react-router-dom";
import Modal from 'react-modal';    
import { use } from "i18next";

const CourseCreateDialog = ({setReload}) => {
    const [open, setOpen] = useState(false);
    const [courseName, setCourseName] = useState('');
    const [shortDescription, setShortDescription] = useState('');
    const [description, setDescription] = useState('');
    const [published, setPublished] = useState(0);
    const [isPublic, setIsPublic] = useState(0);
    const [instructors, setInstructors] = useState([]);
    const [instructor, setInstructor] = useState('');

    useEffect(() => {
        axios.get(environment.baseAPI + '/users/instructors', {
            withCredentials: true,
            headers: {
                Authorization: "Bearer " + localStorage.getItem('authtoken'),
                Accept: 'application/json',
            }
        }).then(res => {
            setInstructors(res.data);

        }).catch(error => {
            if (error.response.status === 401) {
                window.location.href = '/login';
            }
        });
    }, []);

    useEffect(() => {
        if (instructors.length > 0) {
            setInstructor(instructors[0].hash_id);
        }
    }, [instructors]);

    const handleCreate = (e) => {
        e.preventDefault();
        axios.post(environment.baseAPI + '/courses/manage/create', {
            course: {
                name: courseName,
                short_description: shortDescription,
                description: description,
                is_published: published,
                is_public: isPublic,
                instructor_id: instructor
            }
        }, {
            withCredentials: true,
            headers: {
                Authorization: "Bearer " + localStorage.getItem('authtoken'),
                Accept: 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(res => {
            setOpen(false);
            setReload(true);
        }).catch(error => {
            if (error.response.status === 401) {
                window.location.href = '/login';
            }
        });
    }

    return (
        <div>
            <button className="btn-cta" onClick={() => setOpen(true)}>Create</button>
            <Modal 
                className="OpenModal"
                overlayClassName="OpenModalOverlay"
                isOpen={open}
                onRequestClose={() => setOpen(false)}
                contentLabel="Create Course"
                style={{
                    backgroundColor: "rgba(128, 128, 128, 0.75)",
                }}
            >
                <div className='p-10'>
                    <h1 className='mb-5 text-xl'>Create Course</h1>
                    <form onSubmit={(e) => handleCreate(e)}>
                        <div className='flex flex-col gap-5 mb-5'>
                            <div className='flex flex-col'>
                                <label>Course Name</label>
                                <input className='input' type="text" value={courseName} onChange={(e) => setCourseName(e.target.value)} />
                            </div>
                            <div className="flex flex-row gap-5">
                                <div className='flex flex-row gap-3 items-center'>
                                    <label>Instructor</label>
                                    <select className='input' value={instructor} onChange={(e) => setInstructor(e.target.value)}>
                                        {instructors.map((instructor, index) => (
                                            <option className="font-light" key={index} value={instructor.hash_id}>
                                                <b>{instructor.name}</b> (@{instructor.username})
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className='flex flex-row gap-3 items-center flex-grow'>
                                    <label>Short Description</label>
                                    <input className='input flex-grow' type="text" value={shortDescription} onChange={(e) => setShortDescription(e.target.value)} />
                                </div>
                            </div>
                            <div className='flex flex-col'>
                                <label>Description</label>
                                <textarea className='input' type="text" value={description} onChange={(e) => setDescription(e.target.value)} />
                            </div>
                            <div className='flex flex-row gap-5'>
                                <input type="checkbox" checked={(published == 1)} onChange={(e) => setPublished((e.target.checked) ? 1 : 0)} />
                                <label>Published</label>
                            </div>
                            <div className='flex flex-row gap-5'>
                                <input type="checkbox" checked={(isPublic == 1)} onChange={(e) => setIsPublic((e.target.checked) ? 1 : 0)} />
                                <label>Make this course public</label>
                            </div>  
                        </div>
                        <div className="flex flex-row justify-between">
                            <button className='btn-cta-alt' type='submit'>Create</button>
                            <button onClick={() => setOpen(false)} type="button" className='btn-cta-sec btn-secondary'>Cancel</button>
                        </div>
                    </form>
                </div>
            </Modal>
        </div>
    );
}

export default CourseCreateDialog;