import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { environment } from '../environment';
import ReactLoading from 'react-loading';
import { useParams, Link } from 'react-router-dom';
import VideoStreaming from '../components/VideoStreaming';
import { useTranslation } from 'react-i18next';
import { MdPlayCircle } from 'react-icons/md';

const ViewCourse = () => {
    const [course, setCourse] = useState({});
    const [is_enrolled, setIsEnrolled] = useState(false);
    const [loading, setLoading] = useState(true);
    const [studentsEnrolled, setStudentsEnrolled] = useState(0);
    const [instructor, setInstructor] = useState();
    const [user, setUser] = useState({});

    const [decDisplay, setDecDisplay] = useState(true);

    const { courseId } = useParams();

    const {t} = useTranslation();

    useEffect(() => {
        setLoading(true);
        
        axios.get(environment.baseAPI + '/courses/show?id=' + courseId , {
            withCredentials: true, 
            headers: {
                Authorization: "Bearer " + localStorage.getItem('authtoken'),
                Accept: 'application/json',
            }
        }).then(response => {
            setCourse(response.data.course);
            setIsEnrolled(response.data.is_enrolled);
            setStudentsEnrolled(response.data.total_students_enrolled);
            setInstructor(response.data.instructor);
            setLoading(false);
            document.title = response.data.course.name + " - Puspakata";
        }).catch(error => { 
            if (error.response && error.response.status === 401) {
                window.location.href = '/login?redirect=/courses/' + courseId;
            }
        });

        axios.get(environment.baseAPI + '/auth/me', {
            withCredentials: true, 
            headers: {
                Authorization: "Bearer " + localStorage.getItem('authtoken'),
                Accept: 'application/json',
            }
        }).then(response => {
            setUser(response.data.user);
        }).catch(error => {
            if (error.response && error.response.status === 401) {
                window.location.href = '/login?redirect=/courses/' + courseId;
            }
        });
        
    }, []);

    const handleEnrollCourse = () => {
        setLoading(true);
        axios.post(environment.baseAPI + '/courses/enroll', {id: courseId}, {
            withCredentials: true, 
            headers: {
                Authorization: "Bearer " + localStorage.getItem('authtoken'),
                Accept: 'application/json',
            }
        }, {xsrfHeaderName: "XSRF-TOKEN"}).then(response => {
            setLoading(false);
            setIsEnrolled(true);
            setStudentsEnrolled(studentsEnrolled + 1);
        }).catch(error => { 
            if (error.response && error.response.status === 401) {
                // window.location.href = '/login';
            }
        });

    }

    const checkIfUserIsCourseInstructor = () => {
        return (course.instructor_id === user.id) 
    }

    return (
        <div className="w-full">
            { loading ? 
            <div className='h-screen min-w-full flex items-center justify-center'>    
                <ReactLoading type={"spinningBubbles"} color={"#b7145a"} height={40} width={40} /> 
            </div>
            :
            <div>
                <div className="p-10 hero-container h-fit w-full text-left">
                    <p className="font-semibold text-lg w-fit mb-3">{t('app.format.courses.view.title')}</p>
                    <div className="flex flex-row gap-10 w-full flex-wrap justify-between">
                        <div className="flex flex-col justify-between w-fit max-w-full">
                            <div className='mb-5 w-96 max-w-full'>
                                <h1 className="text-6xl font-semibold w-1/3 mb-5">{course.name}</h1>
                                <p className="text-lg mb-5 w-fit">{course.description}</p>
                                {
                                course && course.intro_video &&
                                <div className='relative'>
                                    <VideoStreaming className="relative z-0" url={"/courses/intro?course_id=" + course.hash_id} title={t("app.format.utils.introductionvideo") + course.name} />
                                    <div onClick={() => setDecDisplay(false)} className={'z-10 absolute top-0 left-0 rounded-lg w-full h-full backdrop-blur-sm'} style={{display: (decDisplay ? "block" : "none")}}>
                                        <div className='z-0 absolute top-0 left-0 h-full w-full opacity-70 transition-all rounded-lg' style={{backgroundColor: "#ffb4fb"}}></div>
                                        <div className='z-10 absolute top-0 left-0 w-full h-full flex flex-row justify-center items-center p-14'>
                                            <h1 className='w-fit text-xl font-semibold text-white'>{t('app.format.courses.view.intro')}</h1>
                                            <MdPlayCircle className='fill-white cursor-pointer' size={80}/>
                                        </div>
                                    </div>
                                </div>
                                }
                            </div>
                            <div className="flex flex-row justify-between w-96 max-w-full">
                                {course.credit_hours &&  <div className="text-center">
                                    <p>{t('app.format.courses.view.stats.credits')}</p>
                                    <h4 className="text-5xl">{course.credit_hours}</h4>
                                </div>}
                                <div className="text-center">
                                    <p>{t('app.format.courses.view.stats.total_hours')}</p>
                                    <h4 className="text-5xl">5.6</h4>
                                </div>
                                <div className="text-center">
                                    <p>{t('app.format.courses.view.stats.students_enrolled')}</p>
                                    <h4 className="text-5xl">{studentsEnrolled}</h4>
                                </div>
                            </div>
                        </div>
                        <div className="w-96">
                            <div>
                                <p className="text-lg mb-3">{t('app.format.courses.view.lessons')}</p>
                                <div className='overflow-y-scroll nobar max-h-72'>
                                    <div className="border-b-2"></div>
                                    {
                                        (course.lessons === undefined || course.lessons.length === 0) ?
                                        <p className='p-2 text-center'>No lessons available.</p>
                                        :
                                        course.lessons.map(lesson => {
                                            return(
                                            <div className="border-b-2 p-2 flex flex-row justify-between items-center">
                                                <div>
                                                    <p>{lesson.name}</p>
                                                    <p className="text-xs">1 {t('app.format.courses.view.stats.assignments')}, 2 {t('app.format.courses.view.stats.quizzes')}</p>
                                                </div>
                                                <p>10:20</p>
                                            </div>
                                            )
                                        })
                                    }
                                </div>
                                <br/>
                                <br/>
                                { (user && course && checkIfUserIsCourseInstructor()) ? <Link to={"/manage/courses/" + course.hash_id}><button className="px-10 py-2 rounded-full border-2 mt-5 bg-white hover:bg-opacity-50S bg-opacity-100 transition-opacity">{t('app.format.courses.view.manage')}</button></Link> : 
                                    <>{ !is_enrolled ? <button className="px-10 py-2 rounded-full border-2 mt-5 bg-white hover:bg-opacity-50S bg-opacity-100 transition-opacity" onClick={() => handleEnrollCourse()}>{t('app.format.courses.view.start')}</button> : ""}</>
                                }
                            </div>
                        </div>  
                    </div>
                </div>
                <div className='text-left'>
                    <div className="p-10">
                        <h1 className="text-2xl font-normal mb-5">{t('app.format.courses.view.instructor')}</h1>
                        <div className='flex flex-row items-center gap-10'>
                            { instructor.avatar != null ?
                                <img id="pfp" src={instructor.avatar} alt="" className="rounded-full pfp w-[150px] h-[150px]"
                                    data-dz-thumbnail="data-dz-thumbnail" />
                            :
                                <img id="pfp"
                                    src={"https://ui-avatars.com/api/?background=D6D6D6&bold=true&name=" + instructor.name} className="rounded-full pfp w-[150px] h-[150px]"
                                    alt="" data-dz-thumbnail="data-dz-thumbnail" />
                            }
                            <div>
                                <Link to={"/profile/" + instructor.username}><h1 className='text-3xl'>{instructor.name}</h1></Link>
                                <p className='font-light'>{instructor.bio}</p>
                            </div>
                        </div>
                            
                    </div>
                </div>
            </div>
            }
        </div>
    );
}

export default ViewCourse;