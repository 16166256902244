import axios from "axios";
import { useEffect, useState } from "react";
import { environment } from "../../environment";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import ReactLoading from "react-loading";
import { timeAgo } from "../../utilities/helpers";

const DocumentViewer = () => {
    const [documentView, setDocumentView] = useState(null);
    const [nextDocuments, setNextDocuments] = useState([]);
    const [loading, setLoading] = useState(true);

    const { id } = useParams();

    useEffect(() => {
        document.title = "Document - Puspakata";

        setLoading(true);
        axios.get(environment.baseAPI + '/library/show?id=' + id + '&getDocument=1', {
            withCredentials: true,
            headers: {
                Authorization: "Bearer " + localStorage.getItem('authtoken'),
                Accept: 'application/json',
            }
        }).then(response => {
            setDocumentView(response.data);
            document.title = response.data.title + " - Puspakata";
            setLoading(false);
        }).catch(error => {
            if (error.response.status === 401) {
                window.location.href = '/login?redirect=/nexus/docs/' + id;
            }
        })

        axios.get(environment.baseAPI + '/library/documents?currentDocument=' + id, {
            withCredentials: true,
            headers: {
                Authorization: "Bearer " + localStorage.getItem('authtoken'),
                Accept: 'application/json',
            }
        }).then(response => {
            nextDocuments(response.data);
        }).catch(error => {
            if (error.response.status === 401) {
                window.location.href = '/login?redirect=/nexus/docs/' + id;
            }
        })
    }, [id]);

    return (
        <div className='text-left p-10'>
            { (loading) ?
            <div className='w-full h-full flex flex-row justify-center items-center'> 
                <ReactLoading type={"spinningBubbles"} color={"#b7145a"} height={40} width={40} />
            </div>
            :
            <div className='flex flex-col sm:flex-row gap-5 w-full'>
                <div className='min-w-3/5 w-full'>
                    <h1 className='text-xl mb-5'>{documentView.title}</h1>
                    <Link to={documentView.users && "/profile/" + documentView.users.username}>
                        <div className="flex flex-row gap-3 text-left items-start">
                            <div className="h-fit">
                                { documentView.users && documentView.users.avatar != null ?
                                    <img id="pfp" src={documentView.users.avatar} alt="" className="rounded-full pfp w-[30px] h-[30px]"
                                        data-dz-thumbnail="data-dz-thumbnail" />
                                :
                                    <img id="pfp"
                                        src={documentView.users &&  "https://ui-avatars.com/api/?background=D6D6D6&bold=true&name=" + documentView.users.name} className="rounded-full pfp"
                                        width="30" alt="" data-dz-thumbnail="data-dz-thumbnail" />
                                }
                            </div>
                            <div>
                                <p className="text-sm m-0 line-clamp-1 w-fit">{documentView.users && documentView.users.name.split(' ').slice(0,2).join(' ')}</p>
                                <p className="text-xs font-light m-0">@{documentView.users && documentView.users.username}</p>
                                <p className='text-xs text-slate-500'>{documentView.created_at && timeAgo(documentView.created_at)}</p>
                            </div>
                        </div>
                    </Link>
                    <p className="my-5">{documentView.description}</p>

                    <iframe className="rounded-lg" src={documentView.url} width="100%" height="500px"></iframe>
                </div>
                <div className='min-w-2/5'>
                    <h1 className='text-xl mb-5'>Related Documents</h1>
                    <div className='grid grid-cols-1 gap-4'>
                        <div>
                            {
                                nextDocuments.map((document, index) => {
                                    return (
                                        <div key={index} className='flex flex-row gap-3'>
                                            <div className='w-1/5 h-20 rounded-lg'></div>
                                            <div className='w-4/5'>
                                                <h1 className='text-sm'>{document.title}</h1>
                                                <p className='text-xs'>{document.description}</p>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
            }
        </div>
    );
}
export default DocumentViewer;