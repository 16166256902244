import axios from 'axios';
import React, { useState } from 'react';
import Modal from 'react-modal';
import { environment } from '../../environment';

const CourseEditDialog = ({ course, setReload }) => {
    const [open, setOpen] = useState(false);
    const [courseName, setCourseName] = useState(course.name);
    const [shortDescription, setShortDescription] = useState(course.short_description);
    const [description, setDescription] = useState(course.description);
    const [published, setPublished] = useState(course.is_published);
    const [isPublic, setIsPublic] = useState(course.is_public);

    const handleUpdate = (e) => {
        e.preventDefault();
        axios.post(environment.baseAPI + '/courses/manage/update', {
            course: {
                hash_id: course.hash_id,
                name: courseName,
                short_description: shortDescription,
                description: description,
                is_published: published,
                is_public: isPublic
            }
        }, {
            withCredentials: true,
            headers: {
                Authorization: "Bearer " + localStorage.getItem('authtoken'),
                Accept: 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(res => {
            setOpen(false);
            setReload(true);
        }).catch(error => {
            if (error.response.status === 401) {
                window.location.href = '/login';
            }
        });
    }

    return (
        <div>
            <button onClick={() => setOpen(true)}>Edit</button>
            <Modal 
                className="OpenModal"
                overlayClassName="OpenModalOverlay"
                isOpen={open}
                onRequestClose={() => setOpen(false)}
                contentLabel="Edit Course"
            >
                <div className='p-10'>
                    <h1 className='mb-5 text-xl'>Edit Course</h1>
                    <form onSubmit={(e) => handleUpdate(e)}>
                        <div className='flex flex-col gap-5 mb-5'>
                            <div className='flex flex-col'>
                                <label>Course Name</label>
                                <input className='input' type="text" value={courseName} onChange={(e) => setCourseName(e.target.value)} />
                            </div>
                            <div className='flex flex-col'>
                                <label>Short Description</label>
                                <input className='input' type="text" value={shortDescription} onChange={(e) => setShortDescription(e.target.value)} />
                            </div>
                            <div className='flex flex-col'>
                                <label>Description</label>
                                <textarea className='input' type="text" value={description} onChange={(e) => setDescription(e.target.value)} />
                            </div>
                            <div className='flex flex-row gap-5'>
                                <input type="checkbox" checked={(published == 1)} onChange={(e) => setPublished((e.target.checked) ? 1 : 0)} />
                                <label>Published</label>
                            </div>
                            <div className='flex flex-row gap-5'>
                                <input type="checkbox" value={(isPublic == 1)} onChange={(e) => setIsPublic((e.target.checked) ? 1 : 0)} />
                                <label>Make this course public</label>
                            </div>  
                        </div>

                        <div className="flex flex-row justify-between">
                            <button className='btn-cta-alt' type='submit'>Edit</button>
                            <button onClick={() => setOpen(false)} type="button" className='btn-cta-sec btn-secondary'>Cancel</button>
                        </div>
                    </form>
                </div>
            </Modal>
        </div>
    )
}

export default CourseEditDialog;