import { MdAdd, MdStarHalf } from "react-icons/md";
import Modal from "react-modal";
import { useState } from "react";
import axios from "axios";
import { environment } from "../../environment";

const LessonCreateDialog = () => {

    const [open, setOpen] = useState(false);
    const [lessonName, setLessonName] = useState('');
    const [lessonDescription, setLessonDescription] = useState('');
    const [lessonVideo, setLessonVideo] = useState('');
    const [lessonThumbnail, setLessonThumbnail] = useState('');

    const handleCreateLesson = () => {
        // upload multipart form data
        const formData = new FormData();
        formData.append('lessonName', lessonName);
        formData.append('lessonDescription', lessonDescription);
        formData.append('lessonVideo', lessonVideo);
        formData.append('lessonThumbnail', lessonThumbnail);

        axios.post(environment.baseAPI + '/lessons', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${localStorage.getItem('token')}`,
                Accept: 'application/json'
            },
            withCredentials: true
        }).then(response => {
            console.log(response.data);
        }).catch(error => {
            console.log(error);
        });
    }
    return (
        <div>
            <button className="bg-blue-500 text-white p-2 rounded-md" onClick={() => setOpen(true)}><MdAdd /></button>
            <Modal 
                isOpen={open}
                onRequestClose={() => setOpen(false)}
                className="OpenModal"
                overlayClassName="OpenModalOverlay"
            >
                <div className="p-10">
                    <h1 className="mb-5">Create a Lesson</h1>
                    <form method="POST" onSubmit={() => handleCreateLesson()}>
                        <div className="flex flex-col gap-5">
                            <input className="text-black w-full rounded-full px-5 py-3" type="text" placeholder="Lesson Name" value={lessonName} onChange={(e) => setLessonName(e.target.value)} />
                            <textarea className="text-black w-full rounded-3xl px-5 py-3" type="text" placeholder="Lesson Description" value={lessonDescription} onChange={(e) => setLessonDescription(e.target.value)} />

                            <div className="flex flex-row gap-5 ">
                                <div>
                                    <input hidden type="file" placeholder="Lesson Video" value={lessonVideo} onChange={(e) => setLessonVideo(e.target.value)} />
                                    <button type="button" className="btn-cta-alt" onClick={() => document.querySelector('input[type="file"]').click()}>Upload Video</button>
                                </div>

                                <div>
                                    <input hidden type="file" placeholder="Lesson Thumbnail" value={lessonThumbnail} onChange={(e) => setLessonThumbnail(e.target.value)} />
                                    <button type="button" className="btn-cta-alt" onClick={() => document.querySelector('input[type="file"]').click()}>Upload Thumbnail</button>
                                </div>
                            </div>

                            <div className="flex flex-row justify-between">
                                <input type="submit" value="Create Lesson" className="btn-cta-alt" />
                                <input type="button" value="Cancel" className="btn-cta-alt" onClick={() => setOpen(false)} />    
                            </div>
                        </div>
                    </form>
                </div>
            </Modal>
        </div>
    )
}

export default LessonCreateDialog;