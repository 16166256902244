import axios from "axios";
import { useEffect, useState } from "react"
import AdminDashboard from "../components/dashboards/AdminDashboard";
import InstitutionDashboard from "../components/dashboards/InstitutionDashboard";
import LecturerDashboard from "../components/dashboards/LecturerDashboard";
import {environment} from "../environment";

const Dashboard = () => {
    const [user, setUser] = useState({});

    useEffect(() => {
        document.title = "Dashboard - Puspakata";

        axios.get(environment.baseAPI + '/auth/me', {
            withCredentials: true, 
            headers: {
                Accept: 'application/json',
                Authorization: "Bearer " + localStorage.getItem('authtoken')
            }
        }).then(response => {
            setUser(response.data.user);
            
            console.log(user.role.name);
        }).catch(error => {
            if (error.response && error.response.status === 401) {
                window.location.href = '/login';
            }
        });
    }, []);

    useEffect(() => {
        if (user && user.role && user.role.name === 'Student') {
            window.location.href = '/courses';
        }
    }, [user]);

    return (
        <div>
            {user && user.role && user.role.name === 'Admin' && <AdminDashboard />}
            {user && user.role && user.role.name === 'Institution' && <InstitutionDashboard />}
            {user && user.role && user.role.name === 'Lecturer' && <LecturerDashboard />}
        </div>
    )
}

export default Dashboard;