import { useEffect, useState } from "react";
import axios from "axios";
import { environment } from "../../environment";
import CourseEditDialog from "../../components/management/CourseEditDialog";
import CourseDeleteDialog from "../../components/management/CourseDeleteDialog";    
import { useNavigate } from "react-router-dom";
import CourseCreateDialog from "../../components/management/CourseCreateDialog";
import { Link } from "react-router-dom";    

const CoursesManagement = () => {
    const [courses, setCourses] = useState([]);
    const [reload, setReload] = useState(false);
    const [user, setUser] = useState(null);

    const nav = useNavigate();

    useEffect(() => {
        document.title = "Course Management - Puspakata";

        axios.get(environment.baseAPI + '/courses/manage/get', {
            withCredentials: true, 
            headers: {
                Authorization: "Bearer " + localStorage.getItem('authtoken')
            }
        }).then(res => {
            setCourses(res.data);
            setReload(false);
        }).catch(error => {
            if (error.response.status === 401) {
                window.location.href = '/login';
            }
        });

        axios.get(environment.baseAPI + '/auth/me', {
            withCredentials: true, 
            headers: {
                Authorization: "Bearer " + localStorage.getItem('authtoken'),
                Accept: 'application/json',
            }
        }).then(res => {
            setUser(res.data.user);
        }).catch(error => {
            // if (error.response.status === 401) {
                window.location.href = '/login';
            // }
        });

    }, [reload]);

    return (
        <div className="text-left p-10">
            <div className="flex flex-row justify-between mb-5 items-center">
                <h1 className="text-xl">Course Management {user && user.role && ((user.role.name === "Admin") ? "" : ("- " + user.institution.name)) }</h1>
                { user && user.role && ((user.role.name === "Admin" || user.role.name === "Institution") ? <CourseCreateDialog setReload={setReload} /> : "")}
            </div>
            <div className=""> 
                <table className="w-full">
                    <thead className="dark:bg-slate-700 dark:text-white text-slate-400">
                        <tr className="border">
                            <th className="p-2 font-light">Course Name</th>
                            <th className="p-2 font-light">Instructor</th>
                            <th className="p-2 font-light">Actions</th>
                            <th className="p-2 font-light text-center">Published</th>
                        </tr>
                    </thead>
                    <tbody className="text-slate-500 dark:text-white">
                        {
                            courses.length === 0 ?
                            <tr>
                                <td colSpan="4" className="text-center p-5">No courses found.</td>
                            </tr>
                            :
                            <>
                                {

                                    courses.map(course => {
                                        return(
                                        <tr className="border dark:hover:bg-slate-700 hover:bg-gray-100 transition-colors">
                                            <td className="p-2">
                                                <Link to={"/courses/" + course.hash_id} className="">
                                                    <h1 className="text-md">{course.name}</h1>
                                                    <p className="text-slate-500 text-sm">{course.short_description}</p>
                                                </Link>
                                            </td>
                                            <td className="p-2">
                                                <div className="flex flex-row items-center gap-3">
                                                    <div className="cursor-pointer" onClick={() => nav('/profile/' + course.users.username)}>
                                                        { 
                                                            course && course.users && course.users.avatar != null ?
                                                            <img id="pfp" src={course.users.avatar} width="40" alt="" className="rounded-full pfp"
                                                                data-dz-thumbnail="data-dz-thumbnail" />
                                                            :
                                                            <img id="pfp"
                                                                src={"https://ui-avatars.com/api/?background=D6D6D6&bold=true&name=" + course.users.name} className="rounded-full pfp w-[40px] h-[40px]"
                                                                alt="" data-dz-thumbnail="data-dz-thumbnail" />
                                                        }
                                                    </div>
                                                    <div className="">
                                                        <h1 className="text-md">{course && user && (course.users.hash_id === user.hash_id ? "You" : course.users.name)}</h1>
                                                        <p className="text-slate-500 text-sm">@{course.users.username}</p>
                                                    </div>
                                                </div>
                                            </td>
                                            <td className="p-2 flex flex-row gap-3 text-blue-500 items-center">
                                                <CourseEditDialog course={course} setReload={setReload} />
                                                { user && user.role && ((user.role.name === "Admin" || user.role.name === "Institution") ? <CourseDeleteDialog course={course} setReload={setReload}/> : "") }
                                            </td>
                                            <td className="p-2"><div className={"w-2 h-2 mx-auto rounded-full " + (course.is_published === 1 ? "bg-green-500": "bg-red-500")}></div></td>
                                        </tr>
                                        )})
                                }
                            </>
                        }
                    </tbody>
                </table>

                {/* {
                    courses.map(course => {
                        return (
                            <div className="w-full flex flex-row gap-5 border-b py-5">
                                <div className="w-1/3">
                                    <h1 className="text-xl">{course.name}</h1>
                                    <p className="text-slate-500">{course.short_description}</p>
                                </div>
                                <div className="w-1/3">
                                    <h1 className="text-xl">{course.users.name}</h1>
                                    <p className="text-slate-500">{course.users.email}</p>
                                </div>
                                <div className="w-1/3 flex flex-row gap-3">
                                    <CourseEditDialog course={course} setReload={setReload} />
                                    <CourseDeleteDialog course={course} setReload={setReload}/>
                                </div>
                            </div>
                        );
                    })
                } */}
            </div>
        </div>
    );
}

export default CoursesManagement;