/**
 * Flagged words for content moderation purposes.
 * 
 * Words are separated by language and then by category.
 * 
 */

export const checkFlaggedWords = (text) => {
    return flaggedWords.some(word => text.toLowerCase().includes(word))
}

export const flaggedWords = [
    // [ English ]
    
    // Typical pejoratives
    'fuck',
    'shit',
    'damn',
    'goddamn',
    'fck',
    'sht',
    'shut the fuck up',
    'piece of shit',
    'fucking',
    'bitch',

    // Genitalia etc,
    'cunt',
    'cuntfuck',
    'dick',
    'dickhead',
    'asshole',
    'tits',
    'titty',
    'titties',

    // Slurs, Racism and Violence
    'beaner',
    'yellowface',
    'towelhead',
    'nigga',
    'nigger',
    'retard',
    'retarded',

    // [ Indonesian ]

    // Typical pejorative
    'anjir',
    'kontol',
    'cibai',
    'jibai',
    'sundal',

    // [ Malay and Malaysian ]
    
    // Typical pejorative
    'bodo',
    'bodoh',
    'babi',
    'bangsat',
    'kimak',
    'pukimak',
    'sial',
    'lancau',
    'lanjiao',
    'lanjiau',
    'buto',
    'butoh',
    'gi mampos',
    'gi mampus',

    // Genitalia etc,
    'kepala bapak kau',
    'kepala buto',
    'pale buto',
    'ale uto',
    'pantat',
    'konek',
    'telo',
    'kote',
    'pelir',
    'puki',

    // Sexual
    'kongkek',
    'kau dah sedap',
    'sula',
    'liwat',
    'bontot',
    'jubo',
    'jubur',
    
    // Slurs, racism and violence
    'keling',
    'mata sepet',
    'indon',
    'bangla',
    'negro',
]