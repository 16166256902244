import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Login from './pages/Login';
import Layout from './pages/Layout';
import Home from './pages/Home';
import Courses from './pages/Courses';
import ViewCourse from './pages/ViewCourse';
import Profile from './pages/Profile';
import AccountSettings from './pages/AccountSettings';
import Dashboard from './pages/Dashboard';
import ManagementLayout from './pages/ManagementLayout';
import CoursesManagement from './pages/management/CoursesManagement';
import ManageCourse from './pages/management/ManageCourse';
import Modal from 'react-modal';
import ForgotPassword from './pages/ForgotPassword';
import ResetPassword from './pages/ResetPassword';
import Nexus from './pages/nexus/Nexus';
import Watch from './pages/nexus/Watch';
import DocumentViewer from './pages/nexus/DocumentViewer';
import Messenger from './pages/Messenger';
import Forums from './pages/Forums';

// Modal.setAppElement('#pz-target');

function App() {
  return (
    <div className="App" id='pz-target'>
      <BrowserRouter>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/" element={<Layout />} >
            <Route index element={<Home />} />
            <Route path="courses">
              <Route index element={<Courses />} />
              
              <Route path=":courseId" element={<ViewCourse />} />
            </Route>
            <Route path="profile/:username" element={<Profile />} />
            <Route path="settings">
              <Route path="account" element={<AccountSettings />} />
            </Route>

            <Route path="messages" element={<Messenger />} />

            <Route path="nexus" >
              <Route index element={<Nexus />} />
              <Route path="watch/:id" element={<Watch />} />
              <Route path="docs/:id" element={<DocumentViewer />} />
            </Route>

            <Route path="forums">
              <Route index element={<Forums />} />
            </Route>

          </Route>  
          <Route path="/manage" element={<ManagementLayout />} >
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="courses" element={<CoursesManagement />} />
            <Route path="courses/:courseId" element={<ManageCourse />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
