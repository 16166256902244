import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import ReactLoading from 'react-loading';
import { MdCheck, MdVisibility, MdVisibilityOff } from 'react-icons/md';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { environment } from '../environment';
import Modal from 'react-modal';
import { MdCheckCircle } from 'react-icons/md';

const ForgotPassword = () => {

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [visible, setVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const nav = useNavigate();

    useEffect(() => {
        document.title = "Forgot Your Password? - Puspakata";
    }, []);

    const handleForgotPassword = () => {
        setLoading(true);
        axios.post(environment.baseAPI + '/auth/forgot-password', {
            email: email
        }).then(response => {
            setLoading(false);
            setSuccess(true);
        }).catch(error => {
            setLoading(false);
            setError(true);
        });
    }

    return (
        <>
            <div className="h-screen flex flex-row items-center">
                <form className="my-auto flex flex-col w-1/2 p-10 gap-6" onSubmit={e => {e.preventDefault(); handleForgotPassword()}}>
                    <img className="w-72" src="/PuspakataLogo.png" />
                    <div>
                        <h1 className="text-left text-3xl mb-2">Forgot your password?</h1>
                        <p className='text-left'>Don't worry, we'll send you an email for you to reset your password.</p>
                    </div>
                    <input required className="text-slate-700 shadow-lg rounded-full p-1 px-5" placeholder="Email" type="email" value={email} onChange={e => setEmail(e.target.value)} />
                    
                    {(error) ? <p className="text-red-500">We had some trouble getting to you. Please try again later</p> : ""}
                    <div className="flex flex-row justify-between items-center">
                        <button onClick={() => handleForgotPassword()} type="submit" className="btn-cta">Send me an email</button>
                        {(loading) ? <ReactLoading type={"spinningBubbles"} color={"#b7145a"} height={40} width={40} /> : ""}
                    </div>
                </form>
                <div className="w-1/2 h-screen p-10">
                    <img className="h-full w-full object-cover rounded-3xl" src="/LoginImage.jpg" />
                </div>
            </div>
            <Modal 
                className="OpenModal"
                overlayClassName="OpenModalOverlay"
                isOpen={success}
                onRequestClose={() => setSuccess(false)}
                contentLabel="Edit Course"
            >
                <div className='p-10 text-center flex flex-col h-full items-center justify-center'>
                    <MdCheckCircle className="text-green-500 text-6xl mb-5" />
                    <h1 className='mb-5 text-xl'>Success</h1>
                    <p>Your password has been reset.</p>
                    <div className='flex flex-row justify-center gap-5 mt-5'>
                        <button onClick={() => {setSuccess(false); nav('/login')}} className="btn-cta">Back to Login</button>
                    </div>
                </div>
            </Modal> 
        </>
    );
}

export default ForgotPassword;
