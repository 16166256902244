import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import axios from 'axios';
import { environment } from '../../environment';
import { useTranslation } from 'react-i18next';
import VideoStreaming from '../../components/VideoStreaming';
import { Link } from 'react-router-dom';
import ReactLoading from 'react-loading';
import FormatHyperlinkText from '../../components/FormatHyperlinkText';
import { timeAgo, timeFormat } from '../../utilities/helpers';

const Watch = () =>{
    const { id } = useParams();
    const [loading, setLoading] = useState(true);
    const [content, setContent] = useState({});

    const [nextVideos, setNextVideos] = useState([]);

    const nav = useNavigate();

    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        document.title = "Watch - Puspakata";

        setLoading(true);
        axios.get(environment.baseAPI + '/library/show?id=' + id, {
            withCredentials: true,
            headers: {
                Authorization: "Bearer " + localStorage.getItem('authtoken'),
                Accept: 'application/json',
            }
        }).then(response => {
            setContent(response.data);

            document.title = response.data.title + " - Puspakata";
            setLoading(false);
        }).catch(error => {
            if (error.response && error.response.status === 401) {
                nav('/login?redirect=/nexus/watch/' + id);
            }
        });

        axios.get(environment.baseAPI + '/library/videos?currentVideo=' + id, {
            withCredentials: true,
            headers: {
                Authorization: "Bearer " + localStorage.getItem('authtoken'),
                Accept: 'application/json',
            }
        }).then(response => {
            setNextVideos(response.data);
        }).catch(error => {
            if (error.response && error.response.status === 401) {
                nav('/login?redirect=/nexus/watch/' + id);
            }
        });

    }, [id]);

    const ts = searchParams.get('ts') | 0;

    const {t} = useTranslation();

    return (
        <div className='text-left p-10'>
            { (loading) ?
            <div className='w-full h-full flex flex-row justify-center items-center'> 
                <ReactLoading type={"spinningBubbles"} color={"#b7145a"} height={40} width={40} />
            </div>
            :
            <div className='flex flex-col sm:flex-row gap-5'>
                <div className='min-w-3/5'>
                    {id && <VideoStreaming url={'/library/stream?id=' + id} title={content.title} autoPlay={true} id={id} saveTime={true} ts={ts} subtitles={content.subtitles_path} />}
                    <div className='flex flex-row justify-between gap-5 mt-5 items-center'>
                        <div>
                            <p className='line-clamp-1 text-xs text-slate-500 bg-slate-200 w-fit px-1 rounded mb-1'>{content.courses && content.courses.name}</p>
                            <h1 className='text-xl'>{content.title}</h1>
                        </div>
                        <h1 className=''>{Intl.NumberFormat().format(content.views) + (content.views == 1 ? " view" : " views")}</h1>
                    </div>
                    <div className='mt-2 w-fit'>
                        <Link to={content.users && "/profile/" + content.users.username}>
                            <div className="flex flex-row gap-3 text-left items-start">
                                <div className="h-fit">
                                    { content.users && content.users.avatar != null ?
                                        <img id="pfp" src={content.users.avatar} alt="" className="rounded-full pfp w-[30px] h-[30px]"
                                            data-dz-thumbnail="data-dz-thumbnail" />
                                    :
                                        <img id="pfp"
                                            src={content.users &&  "https://ui-avatars.com/api/?background=D6D6D6&bold=true&name=" + content.users.name} className="rounded-full pfp"
                                            width="30" alt="" data-dz-thumbnail="data-dz-thumbnail" />
                                    }
                                </div>
                                <div>
                                    <p className="text-sm m-0 line-clamp-1 w-fit">{content.users && content.users.name.split(' ').slice(0,2).join(' ')}</p>
                                    <p className="text-xs font-light m-0">@{content.users && content.users.username}</p>
                                    <p className='text-xs text-slate-500'>{content.created_at && timeAgo(content.created_at)}</p>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className='mt-5'>
                        <hr className='p-2'/>
                        {/* <p className='text-sm mb-3'>Description</p> */}
                        <p className='text-sm text-slate-500 whitespace-pre-line'><FormatHyperlinkText text={content.description} /></p>
                    </div>
                </div>
                <div className='p-5 bg-slate-200 dark:bg-slate-600 w-full h-fit rounded-lg'>
                    <h1>Watch next</h1>
                    <div className='flex flex-col gap-5 mt-5'>
                        {
                            nextVideos.map((video, index) => {
                                return (
                                    <Link to={"/nexus/watch/" + video.hash_id + ((video.timestamp) ? "?ts=" + video.timestamp : "") } className='grid grid-cols-5 gap-5' key={index}>
                                        <div className='aspect-video rounded-lg col-span-2'>
                                            <div className='relative'>
                                                <img src={video.thumbnail_path} className='relative w-full aspect-video object-cover rounded-lg' />
                                                {
                                                video.duration != null &&
                                                <div className="absolute z-10 bottom-0 left-0 w-full">
                                                    <div className="w-fit text-xs bg-black bg-opacity-50 text-white p-1 rounded-lg">{timeFormat(video.duration)}</div>
                                                    { video.timestamp && (video.timestamp > 0) ?
                                                    <div className="w-full relative">
                                                        <div className="absolute bottom-0 z-10 h-1 bg-[#b7145a]" style={{width: String(Math.round((video.timestamp / video.duration) * 100)) + "%"}}></div>
                                                        <div className="absolute bottom-0 left-0 w-full h-1 bg-slate-100"></div>
                                                    </div>
                                                    :
                                                    ""
                                                    }
                                                </div>
                                                }
                                            </div>
                                        </div>
                                        
                                        <div className='flex-2 col-span-3'>
                                            <p className='line-clamp-1 text-xs text-slate-500 bg-slate-200 w-fit px-1 rounded'>{video.courses && video.courses.name}</p>
                                            <h1 className='text-sm line-clamp-1'>{video.title}</h1>
                                            <p className='text-xs text-slate-500 line-clamp-1'>{video.users.name}</p>
                                            <div className="flex flex-row">
                                                <p className="line-clamp-1 text-xs text-slate-500">{Intl.NumberFormat().format(video.views) + (video.views == 1 ? " view" : " views")}</p>
                                                <span className="mx-2 text-xs text-slate-500">&middot;</span>
                                                <p className="line-clamp-1 text-xs text-slate-500">{video.created_at && timeAgo(video.created_at)}</p>
                                            </div>
                                        </div>
                                    </Link>
                                );
                            })
                        }
                    </div>
                </div>
            </div>
            }
        </div>
    );
}

export default Watch;