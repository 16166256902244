import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';
import { pdfjs } from 'react-pdf';
import Echo from 'laravel-echo';
import {environment} from './environment';

import common_id from "./translations/id/common.json";
import common_en from "./translations/en/common.json";
import Pusher from 'pusher-js';
import axios from 'axios';

i18next.init({
    interpolation: { escapeValue: false },  
    lng: localStorage.getItem('i18nextLng') || 'en',                              
    resources: {
        en: {
            translation: common_en         
        },
        id: {
            translation: common_id
        },
    },
});

window.Pusher = Pusher

window.Echo = new Echo({
    broadcaster: 'pusher',
    authHost: environment.baseAPIRef,
    authEndpoint: 'http://localhost:8000/broadcasting/auth',
    key: 12345,
    cluster: 12345,
    wsHost: window.location.hostname, // Set the WebSocket host to localhost
    wssHost: window.location.hostname, // Set the secure WebSocket host to localhost
    wsPort: 6001, // Set the WebSocket port to 6001
    wssPort: 6001, // Set the secure WebSocket port to 6001
    disableStats: true, // Disable stats to prevent sending requests to Pusher
    enabledTransports: ['ws', 'wss'], // Enable WebSocket and secure WebSocket transports
    forceTLS: false, // Ensure that TLS is not forced
    encrypted: false, // Ensure that the connection is not encrypted
    csrfToken: null, // Set to null if you're not using CSRF protection
    authorizer: (channel, options) => {
        return {
            authorize: (socketId, callback) => {
                axios({
                method: "POST",
                url: environment.baseAPIRef + "/broadcasting/auth",
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('authtoken')}`,
                    Accept: "application/json",
                },
                data: {
                    socket_id: socketId,
                    channel_name: channel.name,
                },
                })
                .then((response) => {
                    callback(false, response.data);
                })
                .catch((error) => {
                    callback(true, error);
                });
            },
        };
    },
})

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <I18nextProvider i18n={i18next}>
      <App />
    </I18nextProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
