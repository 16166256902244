import Modal from 'react-modal';
import axios from 'axios';
import { environment } from '../../environment';
import { useState } from 'react';

const CourseDeleteDialog = ({course, setReload}) => {
    const [open, setOpen] = useState(false);
    
    const handleDelete = () => {
        axios.post(environment.baseAPI + '/courses/manage/delete', {
            hash_id: course.hash_id
        }, {
            withCredentials: true,
            headers: {
                Authorization: "Bearer " + localStorage.getItem('authtoken'),
                Accept: 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(res => {
            setOpen(false);
            setReload(true);
        }).catch(error => {
            if (error.response.status === 401) {
                window.location.href = '/login';
            }
        });
    }

    return (
        <div className=''>
            <button onClick={() => setOpen(true)}>Delete</button>
            <Modal 
            className="OpenModal"
            overlayClassName="OpenModalOverlay"
            isOpen={open}
            onRequestClose={() => setOpen(false)}
            contentLabel="Delete Course">
                <div className='text-center py-12 px-10'>
                    <h1 className='mb-5 text-xl'>Delete Course</h1>
                    <p>Are you sure you want to delete this course?</p>
                    <div className='flex flex-row gap-5 mt-5 justify-center items-center w-full '>
                        <button onClick={() => handleDelete()} className='btn-cta-sec btn-primary'>Delete</button>
                        <button onClick={() => setOpen(false)} className='btn-cta-alt btn-secondary'>Cancel</button>
                    </div>
                </div>
            </Modal>
        </div>
    );
}

export default CourseDeleteDialog;