import {useTranslation} from "react-i18next";
import { MdLanguage } from "react-icons/md";

const LanguageSelector = () =>
{
    const [t, i18n] = useTranslation('common');

    return <div className="flex flex-row items-center gap-2 flex-wrap">
        <span><MdLanguage/> </span>
        {/* <p>Tukar Bahasa</p> */}
        <div className="flex flex-row">
            <button onClick={() => {i18n.changeLanguage('id'); localStorage.setItem('i18nextLng', 'id')}} className={"px-2 emoji rounded-l " + (i18n.language == 'id' ? "lang-sel" : "lang-des")}>&#127470;&#127465;</button>
            <button onClick={() => {i18n.changeLanguage('en'); localStorage.setItem('i18nextLng', 'en')}} className={"px-2 emoji rounded-r " + (i18n.language == 'en' ? "lang-sel" : "lang-des")}>&#127468;&#127463;</button>
        </div>
    </div>
}

export default LanguageSelector;