import { environment } from "../environment";   
import axios from "axios";
import { useState, useEffect } from "react";

import { MdVisibility, MdVisibilityOff } from "react-icons/md"
import { Link, useSearchParams } from "react-router-dom";
import ReactLoading from "react-loading";
import { useNavigate } from "react-router-dom";


const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [visible, setVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const nav = useNavigate();

    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        document.title = "Login - Puspakata";

        axios.get(environment.baseAPI + '/auth/me', {withCredentials: true, headers: {Authorization: "Bearer " + localStorage.getItem('authtoken')}}).then(res => {
            nav('/');
        }).catch(error => {
            if (error.response.status === 401) {
                localStorage.removeItem('authtoken');
            }
        });
    }, []);

    const handleLogin = () => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 3000);
        axios.get(environment.baseAPIRef + '/sanctum/csrf-cookie').then(response => {
            axios.post(environment.baseAPI + '/auth/login', {
                email: email,
                password: password,
                device_name: 'browser'
            }, {xsrfHeaderName: "XSRF-TOKEN"})
            .then(response => {
                localStorage.setItem('authtoken', response.data.token);
            }).catch(error => {
                setError(true);
            });
        }).catch(error => {

        });
    }

    useEffect(() => {
        if (localStorage.getItem('authtoken')) {
            axios.get(environment.baseAPI + '/auth/me', {withCredentials: true, headers: {Authorization: "Bearer " + localStorage.getItem('authtoken')}}).then(res => {
                const redirect = searchParams.get('redirect');
                if(res.data.user.role.name === 'Admin' || res.data.user.role.name === 'Institution' || res.data.user.role.name === 'Lecturer') {
                    nav('/manage/dashboard');
                    
                }
                else {
                    if(redirect) {
                        nav(redirect);
                    }
                    else {
                        nav('/')
                    }
                }
            }).catch(error => {
                if (error.response.status === 401) {
                    localStorage.removeItem('authtoken');
                }
            });
        }
    }, [localStorage.getItem('authtoken')]);

    return (
        <div className="h-screen flex flex-row items-center">
            <form className="my-auto flex flex-col w-1/2 p-10 gap-6" onSubmit={e => {e.preventDefault(); handleLogin()}}>
                <img className="w-72" src="/PuspakataLogo.png" />
                <h1 className="text-left text-3xl mb-1">Login</h1>
                <input required className="text-slate-700 shadow-lg rounded-full p-1 px-5" placeholder="Email" type="email" value={email} onChange={e => setEmail(e.target.value)} />
                <div class="text-slate-700 m-0 h-fit flex flex-row items-center shadow-lg rounded-full bg-white">
                    <input required className="rounded-l-full p-1 px-5 w-full" placeholder="Password" type={ visible ? "text" : "password" } value={password} onChange={e => setPassword(e.target.value)} />
                    <button type="button" onMouseDown={() => setVisible(true)} onMouseUp={() => setVisible(false)} className="px-3"><span className="">{ !visible ? <MdVisibility className="fill-black" /> : <MdVisibilityOff className="fill-black" /> }</span></button>
                </div>
                <p>Forgot your password? Click <Link to="/forgot-password" className="underline text-blue-400">here.</Link></p>
                {(error) ? <p className="text-red-500">Invalid email or password.</p> : ""}
                <div className="flex flex-row justify-between items-center">
                    <button onClick={() => handleLogin()} type="submit" className="btn-cta">Login</button>
                    {(loading) ? <ReactLoading type={"spinningBubbles"} color={"#b7145a"} height={40} width={40} /> : ""}
                </div>
            </form>
            <div className="w-1/2 h-screen p-10">
                <img className="h-full w-full object-cover rounded-3xl" src="/LoginImage.jpg" />
            </div>
        </div>
    );
}

export default Login;