import { Link, Outlet, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from "axios";
import { environment } from "../environment";
import { useNavigate } from "react-router-dom";
import LanguageSelector from "../components/LanguageSelector";
import { useTranslation } from "react-i18next";
import { MdMenu } from "react-icons/md";

const Layout = () => {
    const nav = useNavigate();

    const {t} = useTranslation();

    const logout = () => {
        localStorage.removeItem('authtoken');
        window.location.href = '/login';
    }

    const [user, setUser] = useState(null);

    const location = useLocation();

    useEffect(() => {
        axios.get(environment.baseAPI + '/auth/me', {withCredentials: true, headers: {Authorization: "Bearer " + localStorage.getItem('authtoken')}}).then(res => {
            setUser(res.data.user);

        }).catch(error => {
            if (error.response.status === 401) {
                // nav('/login');
            }
        });
    }, []);

    const [menuVisible, setMenuVisible] = useState(false);

    return (
        <div className="flex flex-row">
            <div className="absolute top-0 right-0 z-20 px-10 pt-5 sm:hidden block" onClick={() => setMenuVisible(!menuVisible)}><MdMenu size={40} /></div>
            <div className={"p-6 sm:min-w-56 sm:max-w-56 h-screen top-0 shadow-xl absolute left-0 sm:block sm:sticky z-10 w-screen bg-white dark:bg-slate-800 " + (menuVisible ? "block" : "hidden")}>
                <nav tabIndex={"0"} onClick={() => setMenuVisible(!menuVisible)}>
                    <ul className="text-left flex flex-col gap-3">
                        <li><Link to="/"><img className="w-56 sm:w-full" src="/PuspakataLogo.png" /></Link></li>
                        { user && (user.role.name === 'Admin' || user.role.name === "Institution" || user.role.name === "Instructor") && <li className={"rounded-full " + ((location.pathname == "/manage/dashboard") ? "font-bold" : "")}><Link to="/manage/dashboard">{t('app.format.layout.dashboard')}</Link></li>}
                        <li className={"rounded-full " + ((location.pathname.match(/\/courses/)) ? "font-bold" : "")}><Link to="/courses">{t('app.format.layout.courses')}</Link></li>
                        <li className={"rounded-full " + ((location.pathname.match(/\/nexus/)) ? "font-bold" : "")}><Link to="/nexus/">Nexus</Link></li>
                        <li className={"rounded-full " + ((location.pathname == "/forums") ? "font-bold" : "")}><Link to="/forums">{t('app.format.layout.forums')}</Link></li>
                        <li className={"rounded-full " + ((location.pathname == "/messages") ? "font-bold" : "")}><Link to="/messages">{t('app.format.layout.messages')}</Link></li>
                        <li className={"rounded-full " + ((location.pathname.match(/\/settings/)) ? "font-bold" : "")}><Link to="/settings/account">{t('app.format.layout.settings')}</Link></li>
                        <li><button type="button" onClick={() => logout()}>{t('app.format.layout.logout')}</button></li>
                        <LanguageSelector />
                    </ul>
                </nav>

                <div className="fixed bottom-0 py-5">
                    <Link to={user && "/profile/" + user.username}>
                        <div className="flex flex-row gap-3 text-left items-center">
                            <div className="h-fit">
                                { user && user.avatar != null ?
                                    <img id="pfp" src={user.avatar} alt="" className="rounded-full pfp w-[30px] h-[30px]"
                                        data-dz-thumbnail="data-dz-thumbnail" />
                                :
                                    <img id="pfp"
                                        src={user &&  "https://ui-avatars.com/api/?background=D6D6D6&bold=true&name=" + user.name} className="rounded-full pfp"
                                        width="30" alt="" data-dz-thumbnail="data-dz-thumbnail" />
                                }
                            </div>
                            <div>
                                <p className="text-sm m-0 line-clamp-1 w-fit">{user && user.name.split(' ').slice(0,2).join(' ')}</p>
                                <p className="text-xs font-light m-0">@{user && user.username}</p>
                            </div>
                        </div>
                    </Link>
                </div>
            </div>
            <div className="w-full relative min-h-screen flex flex-col justify-between z-0">
                {/* <div className="flex flex-row w-full px-10 pt-5 sm:justify-end absolute top-0 left-0">
                    <LanguageSelector />
                </div> */}
                <Outlet />
                <footer className="bottom-0 w-full">
                    <div className="bg-slate-100 p-6 dark:bg-slate-700">
                        <div className="w-full mb-5">
                            <img className="w-32 mx-auto sm:mx-0 " src="/PuspakataLogo.png" />
                        </div>
                        <div className="mb-5">
                            <ul className="flex flex-row gap-10 justify-center">
                                <li><Link to="/">{t('app.format.layout.about')}</Link></li>
                                <li><Link to="/">{t('app.format.layout.contact')}</Link></li>
                                <li><Link to="/">{t('app.format.layout.faq')}</Link></li>
                                <li><Link to="/">{t('app.format.layout.terms')}</Link></li>
                                <li><Link to="/">{t('app.format.layout.privacy')}</Link></li>
                            </ul>
                        </div>
                        <div className="container mx-auto">
                            <p className="text-slate-500 text-xs">Site Design & Logo © 2024 EDESS Sdn. Bhd.</p>
                        </div>  
                    </div>
                </footer>
            </div>
        </div>
    )
}

export default Layout; 