import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { environment } from '../environment';
import { useNavigate, useParams } from 'react-router-dom';
import ReactLoading from 'react-loading';
import { MdSchool } from 'react-icons/md';
import { useTranslation } from 'react-i18next'
import EditProfileDialog from '../components/EditProfileDialog';

const Profile = () => {
    const nav = useNavigate();
    const [user, setUser] = useState(null);
    const [selfUser, setSelfUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const [reload, setReload] = useState(false);

    const { username } = useParams();

    const {t} = useTranslation();

    useEffect(() => {
        document.title = "Profile - Puspakata";
        setLoading(true);
        axios.get(environment.baseAPI + '/auth/me', {withCredentials: true, headers: {Authorization: "Bearer " + localStorage.getItem('authtoken')}}).then(res => {
            setSelfUser(res.data.user);
        })
        .then(() => {
            axios.get(environment.baseAPI + '/users/profile?username=' + username, {withCredentials: true, headers: {Authorization: "Bearer " + localStorage.getItem('authtoken')}}).then(res => {
                setUser(res.data);
                setLoading(false);
                setReload(false);
            }).catch(error => {
                
            });
        })
        .catch(error => {
            if (error.response.status === 401) {
                nav('/login?redirect=/profile/' + username);
            }
        });
    }, [reload]);

    const getOccupation = () => {
        switch (user.role.name) {
            case "Admin":
            case "Institution":
                return t('app.format.profile.works_at');
            case "Instructor":
                return t('app.format.profile.instructor_from');
            case "Student":
                return t('app.format.profile.studies_at');
            default:
                return "User";
        }
    }

    return (
        <div className='w-full'>
            { loading ? 
            <div className='h-screen min-w-full flex items-center justify-center'>    
                <ReactLoading type={"spinningBubbles"} color={"#b7145a"} height={40} width={40} /> 
            </div>
            :
            <div className="text-left w-full">
                <div className="card-header position-relative h-[280px] mb-7">
                    <div className="cover-image">
                        <div id="cover-cl" className="bg-holder h-[130px] rounded-3 rounded-bottom-0">
                            {
                            user.banner != null ? 
                                <img src={user.banner} alt="cover" class="img-fit h-[200px] object-cover w-full rounded-3 rounded-bottom-0" />
                                :
                                <div className="bg-slate-500 h-[200px]"></div>
                            }
                        </div>
                    </div>
                    <div className='px-10'>
                    <div className="absolute avatar avatar-5xl avatar-profile img-thumbnail rounded-full">
                        <div className="position-relative">
                            { user.avatar != null ?
                                <img id="pfp" src={user.avatar} alt="" className="rounded-full ring-4 ring-white dark:ring-slate-800 pfp w-[150px] h-[150px]"
                                    data-dz-thumbnail="data-dz-thumbnail" />
                            :
                                <img id="pfp"
                                    src={"https://ui-avatars.com/api/?background=D6D6D6&bold=true&name=" + user.name} className="rounded-full ring-4 ring-white dark:ring-slate-800 pfp w-[150px] h-[150px]"
                                    alt="" data-dz-thumbnail="data-dz-thumbnail" />
                            }
                        </div>
                    </div>  
                    </div>
                </div>
                <div className='flex flex-row justify-between items-start px-10 pb-10'>
                    <div className=''>
                        <div>
                            <h1 className="text-2xl">{user.name}</h1>
                            <h1 className='text-lg text-slate-500 font-light'>@{user.username}</h1>
                        </div>
                        <div className='mt-5'>
                            <p className="text-lg font-light mb-2">{user.bio}</p>
                            <p className='text-slate-500 flex flex-row items-center gap-3'><MdSchool/> {user && user.role && getOccupation()} {user.institution }</p>
                        </div>
                    </div>
                    { user && (user.h_stat && user.oid === selfUser.hash_id) ? 
                        <EditProfileDialog user={user} setReload={setReload} />
                    : null
                    }
                </div>
            </div>
            }

            <div className='mb-10 grid grid-cols-3 gap-5 mx-10'>
                <div className='col-span-2'>
                {
                    loading ?
                    <div className='h-screen min-w-full flex items-center justify-center'>    
                        <ReactLoading type={"spinningBubbles"} color={"#b7145a"} height={40} width={40} /> 
                    </div>
                    :
                    <div className="text-left w-full">
                        <div className='dark:bg-slate-600 bg-slate-200 px-5 py-8 rounded-lg'>
                            <div>
                                <h1 className='text-lg dark:text-slate-100 text-slate-800'>How do I speak English fluently</h1>
                                <p className='text-sm dark:text-slate-200 text-slate-600 font-light'>lorem ipsum dolor sit amet</p>
                            </div>
                        </div>
                    </div>
                }
                </div>
                <div className='col-span-1'>
                {
                    loading ?
                    <div className='h-screen min-w-full flex items-center justify-center'>    
                        <ReactLoading type={"spinningBubbles"} color={"#b7145a"} height={40} width={40} /> 
                    </div>
                    :
                    <div className="text-left w-full">
                        <div className='dark:bg-slate-600 bg-slate-200 px-5 py-8 rounded-lg'>
                            <div>
                                <h1 className='text-lg dark:text-slate-100 text-slate-800'>How do I speak English fluently</h1>
                                <p className='text-sm dark:text-slate-200 text-slate-600 font-light'>lorem ipsum dolor sit amet</p>
                            </div>
                        </div>
                    </div>
                }
                </div>
            </div>
        </div>
    )
}

export default Profile;