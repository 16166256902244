import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { environment } from '../environment';

const Forums = () => {
    const [forumTopics, setForumTopics] = useState([]); 
    const [forumPosts, setForumPosts] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        document.title = "Forums - Puspakata";
        
        axios.get(environment.baseAPI + '/forums/topics',
        {
            withCredentials: true,
            headers: {
                Authorization: `Bearer ${localStorage.getItem('authtoken')}`,
                Accept: 'application/json',
            }
        })
            .then((response) => {
                setForumTopics(response.data);
                setLoading(false);
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);
           
    return (
        <div className='text-left p-10'>
            <div>
                <h1 className='text-xl'>Forums</h1>
            </div>
            <div>
                <div>
                    <div>Your Topics</div>
                    <div>  
                        {forumTopics.map((topic) => (
                            <div key={topic.id}>
                                <Link to={`/forums/${topic.id}`}>
                                    <div className='p-6 bg-slate-100 dark:bg-slate-700 rounded-xl'>
                                        
                                        <div>{topic.title}</div>
                                        <div>{topic.description}</div>
                                    </div>
                                </Link>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Forums;