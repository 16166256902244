import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { environment } from '../environment';
import { Link } from 'react-router-dom';
import ReactLoading from 'react-loading';
import { useTranslation } from 'react-i18next';

const Courses = () => {
    const [courses, setCourses] = useState([]);
    const [loading, setLoading] = useState(true);
    const [searchLoading, setSearchLoading] = useState(false);

    const { t } = useTranslation();
    useEffect(() => {
        setLoading(true);

        document.title = "Courses - Puspakata";
        
        axios.get(environment.baseAPI + '/courses', {
            withCredentials: true, 
            headers: {
                Authorization: "Bearer " + localStorage.getItem('authtoken'),
                Accept: 'application/json',
            }
        }).then(response => {
            setCourses(response.data);
            setLoading(false);
        }).catch(error => { 
            if (error.response.status === 401) {
                window.location.href = '/login?redirect=/courses';
            }
        });
        
    }, []);

    const handleSearch = (query) => {
        setSearchLoading(true);
        axios.get(environment.baseAPI + '/courses/search?q=' + query, {
            withCredentials: true, 
            headers: {
                Authorization: "Bearer " + localStorage.getItem('authtoken'),
                Accept: 'application/json',
            }
        }).then(response => {
            setCourses(response.data);
            setSearchLoading(false);

        }).catch(error => { 
            if (error.response.status === 401) {
                window.location.href = '/login?redirect=/courses';
            }
        });
    }

    return (
        <div className='w-full'>
            { loading ? 
            <div className='h-screen min-w-full flex items-center justify-center'>    
                <ReactLoading type={"spinningBubbles"} color={"#b7145a"} height={40} width={40} /> 
            </div>
            :
            <div className="p-8 mt-3 text-left w-full">
                <div className="mb-5 flex flex-row justify-between">
                    <h1 className="text-3xl">Courses</h1>
                    <div className='flex flex-row gap-5'>
                        { searchLoading ? <ReactLoading type={"bars"} color={"#b7145a"} height={30} width={30} /> : ""}
                        <input className="text-slate-700    shadow-lg rounded-full p-1 px-5" placeholder="Search for courses" type="text" onInput={(e) => handleSearch(e.target.value)} />
                    </div>
                </div>
                
                {
                courses.length == 0 ?
                <div>
                    <img src="/illustrations/loupe.png" className="w-1/3 mx-auto mb-5"/>
                    <h1 className='text-center text-xl'>{t('app.format.courses.no_courses')}</h1>
                </div>
                :
                <div class="flex flex-row justify-between flex-wrap w-full gap-5">
                    {courses.map(course => {
                        return (
                            <Link to={"/courses/" + course.hash_id}  class="w-72 ">
                                <div class="rounded-xl overflow-hidden shadow-xl">
                                    <div class="p-3">
                                        <p class="text-sm text-slate-400">{course.languages && (course.languages.endonym) ? course.languages.endonym : t('app.format.courses.no_lang')}</p>
                                        <h4 class="text-2xl max-w-auto arabic h-16 line-clamp-2">{course.name}</h4>
                                        <p class="text-sm text-slate-400">{course.short_description}</p>
                                        <p class="text-sm text-slate-400 font-light">15 hours  •   {course.users.name}</p>
                                    </div>
                                    <div class="grid grid-cols-7">
                                        <div class="col-span-3 p-3">
                                            <p class="py-2 text-sm text-slate-500">4<br/>Lessons</p>
                                            <p class="py-2 text-sm text-slate-500">3<br/>Quizzes</p>
                                            <p class="py-2 text-sm text-slate-500">6<br/>Assignments</p>
                                            <button class="border-2 border-black dark:border-white mt-3 py-2 w-full rounded-3xl">Start</button>
                                        </div>
                                        <div class="col-span-4 h-64" style={{background: "radial-gradient(circle at top, #80dbff , #ff4dcf)"}}>
                    
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        )
                    })}
                </div>
                }
            </div>
            }
        </div>  
    );
}

export default Courses;