import { useState, useEffect } from "react";
import Modal from "react-modal";
import axios from "axios";
import { environment } from "../environment";
import { MdCancel, MdEdit, MdSchool } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";


const EditProfileDialog = ({ setReload, user }) => {

    const [open, setOpen] = useState(false);
    const [editingBio, setEditingBio] = useState(false);
    const [avatarFile, setAvatarFile] = useState(null);
    const [bannerFile, setBannerFile] = useState(null);
    
    const [editingAvatar, setEditingAvatar] = useState(false);
    const [editingBanner, setEditingBanner] = useState(false);
    const [openBioInput, setOpenBioInput] = useState(false);

    const [bioEdit, setBioEdit] = useState(user.bio);


    const nav = useNavigate();
    const {t} = useTranslation();

    const handleEditAvatarButtonClick = () =>  {
        document.getElementById('editAvatarInput').click();
    }

    const handleEditBannerButtonClick = () =>  {
        document.getElementById('editBannerInput').click();
    }

    const handleAvatarSelect = (e) => {
        const file = e.target.files[0];
        setAvatarFile(file);
        const reader = new FileReader();
        reader.onload = function(e) {
            document.getElementById('pfpEdit').src = e.target.result;
        }
        document.getElementById('pfpEdit').src = reader.readAsDataURL(file);
        
        setEditingAvatar(true);
    }

    const handleBannerSelect = (e) => {
        const file = e.target.files[0];
        setBannerFile(file);
        const reader = new FileReader();
        reader.onload = function(e) {
            document.getElementById('bannerEdit').src = e.target.result;
        }
        document.getElementById('bannerEdit').src = reader.readAsDataURL(file);
        
        setEditingBanner(true);
    }

    const handleCancelEditing = () => {
        setEditingAvatar(false);
        handleCancelBioEdit();

        console.log(!editingAvatar && !editingBio);
    }

    const handleCancelBioEdit = () => {
        setEditingBio(false);
        setEditingBanner(false);
        setOpenBioInput(false);
        setBioEdit(user.bio);
    }

    const handleEditProfile = (e) => {
        e.preventDefault();
        const data = new FormData();
        if (avatarFile != null) {
            data.append('avatar', avatarFile);
        }
        if (bannerFile != null) {
            data.append('banner', bannerFile);
        }
        data.append('bio', bioEdit);

        axios.post(environment.baseAPI + '/users/save', data, {
            withCredentials: true,
            headers: {
                Authorization: "Bearer " + localStorage.getItem('authtoken'),
                'Content-Type': 'multipart/form-data'
            }
        }).then(res => {
            setReload(true);
            setOpen(false);
        }).catch(error => {
            console.log(error);
        });
    }

    return (
        <div className="z-30">
            <button className="btn-cta" onClick={() => setOpen(true)}>{t('app.format.profile.edit_profile')}</button>
            <Modal 
                className="OpenModal"
                overlayClassName="OpenModalOverlay"
                isOpen={open}
                onRequestClose={() => setOpen(false)}
                contentLabel="Edit Course"
            >
                <form onSubmit={(e) => handleEditProfile(e)}>
                    <div>
                        <div className="text-left w-full">
                            <div className="card-header position-relative h-[280px] mb-7">
                                <div className="cover-image">
                                    <div id="cover-cl" className="bg-holder h-[130px] rounded-3 rounded-bottom-0">
                                        <div className="w-full h-[200px]">
                                            {
                                            user.banner != null ? 
                                                <img id="bannerEdit" src={user.banner} alt="cover" class="img-fit h-[200px] object-cover w-full rounded-3 rounded-bottom-0" />
                                                :
                                                <div id="bannerEdit" className="bg-slate-500 h-[200px]"></div>
                                            }
                                            <div className="absolute top-0 w-full h-[200px] bg-black bg-opacity-0 hover:bg-opacity-50 transition-all opacity-0 hover:opacity-100 flex flex-row items-center justify-center">
                                                <MdEdit onClick={() => handleEditBannerButtonClick()} size={50} className="text-white m-auto cursor-pointer" />
                                                <input id="editBannerInput" type="file" className="hidden" accept="image/*" onChange={(e) => handleBannerSelect(e)} />
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>
                                <div className='px-10'>
                                <div className="absolute avatar avatar-5xl avatar-profile img-thumbnail rounded-full">
                                    <div className="position-relative">
                                        { user.avatar != null ?
                                            <img id="pfpEdit" src={user.avatar} alt="" className="rounded-full ring-4 ring-white dark:ring-slate-800 pfp w-[150px] h-[150px]"
                                                data-dz-thumbnail="data-dz-thumbnail" />
                                        :
                                            <img id="pfpEdit"
                                                src={"https://ui-avatars.com/api/?background=D6D6D6&bold=true&name=" + user.name} className="rounded-full ring-4 ring-white pfp dark:ring-slate-800"
                                                width="150" alt="" data-dz-thumbnail="data-dz-thumbnail" />
                                        }
                                    </div>
                                    <div className="absolute top-0 rounded-full w-full h-full bg-black bg-opacity-0 hover:bg-opacity-50 transition-all opacity-0 hover:opacity-100 flex flex-row items-center justify-center">
                                        <MdEdit onClick={() => handleEditAvatarButtonClick()} size={50} className="text-white m-auto cursor-pointer" />
                                        <input id="editAvatarInput" type="file" className="hidden" accept="image/*" onChange={(e) => handleAvatarSelect(e)} />
                                    </div>
                                </div>  
                                </div>
                            </div>
                            <div className='flex flex-row justify-s items-start px-10 pb-10'>
                                <div className=''>
                                    <div>
                                        <h1 className="text-2xl">{user.name}</h1>
                                        <h1 className='text-lg text-slate-500 font-light'>@{user.username}</h1>
                                    </div>
                                    <div className='mt-5'>
                                        <p className="text-slate-300">{(editingBio) ? "Editing " : ""}Bio</p>
                                        <p onClick={() => setOpenBioInput(true) } className="text-lg font-light" hidden={openBioInput}>{user.bio}</p>
                                        <div className="flex flex-row gap-3 items-center mb-3" >
                                            <input type="text" className="bg-transparent border-2 border-slate-500 rounded-lg p-2"  hidden={!openBioInput && !(user.bio == "" || user.bio == undefined)} value={bioEdit} onBlur={(e) => { (e.target.value != user.bio) ? setEditingBio(true) : setEditingBio(false) }} onChange={(e) => setBioEdit(e.target.value)} />
                                            <MdCancel onClick={() =>  handleCancelBioEdit() } size={30} className="text-red-500 cursor-pointer"  hidden={!openBioInput && !(user.bio == "" || user.bio == undefined)} />
                                        </div>
                                        <p className='text-slate-500 flex flex-row items-center gap-3'><MdSchool/> {t('app.format.profile.studies_at')} {user.institution }</p>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                    <div hidden={!editingAvatar && !editingBio && !editingBanner} className={"absolute bottom-10 left-0 right-0 mx-auto w-1/2 p-5 bg-slate-600 rounded-lg " + ((!editingAvatar && !editingBio && !editingBanner) ? "shake-animation-brief" : "")}>
                        <div className="flex flex-row justify-center items-center gap-5">
                            <p>Changes were made</p>
                            <button type="submit" className="btn-cta">Save Changes</button>
                            <button type="button" onClick={() => handleCancelEditing()} className="btn-cta-sec">Cancel</button>
                        </div>
                    </div>
                </form>
                 
            </Modal>
        </div>
    )
}
 
export default EditProfileDialog;