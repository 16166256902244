import axios from "axios";
import { useEffect, useState } from "react";
import { environment } from "../environment";   
import { MdVisibility, MdVisibilityOff } from "react-icons/md";
import ReactLoading from "react-loading";
import { useNavigate } from "react-router-dom";
import Modal from 'react-modal';
import { MdCheckCircle } from 'react-icons/md';

const AccountSettings = () => {
    const [user, setUser] = useState(null);
    const [oldPassword, setOldPassword] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false); 
    const [loading, setLoading] = useState(false);

    const [oldPasswordVisible, setOldPasswordVisible] = useState(false);
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);

    const nav = useNavigate();

    useEffect(() => {
        axios.get(environment.baseAPI + '/auth/me', {
            withCredentials: true, 
            headers: {
                Authorization: "Bearer " + localStorage.getItem('authtoken'),
                Accept: 'application/json',
            }
        }).then(res => {
            setUser(res.data.user);
        }).catch(error => {
            if (error.response.status === 401) {
                nav('/login');
            }
        });
    }
    , []);

    const handleChangePassword = (e) => {
        setLoading(true);
        axios.post(environment.baseAPI + '/auth/change-password', {
            old_password: oldPassword,
            new_password: password,
            new_password_confirmation: confirmPassword
        }, {
            withCredentials: true, 
            headers: {
                Authorization: "Bearer " + localStorage.getItem('authtoken'),
                Accept: 'application/json',
            }
        }).then(res => {
            setLoading(false);
            setSuccess(true);
        }).catch(error => {
            setError(true);
        });
    }

    const handleEditAccount = (e) => {

    } 

    return (
        <div className="p-10 text-left">
            <h1 className="text-xl mb-5">Edit Profile</h1>
            <div>
                <form onSubmit={(e) => handleEditAccount(e)}>
                    <div className="flex flex-col mb-5">
                        <label>Full Name</label>
                        <input className="border-2 px-2 rounded-lg" disabled type="text" value={user && user.name} />
                    </div>
                    <div className="flex flex-col mb-5">
                        <label>Email</label>
                        <input className="bg-transparent border-2 px-2 rounded-lg" type="text" value={user && user.email} />
                    </div>
                    <div className="flex flex-col mb-5">
                        <label>Role</label>
                        <input className="border-2 px-2 rounded-lg" disabled type="text" value={user && user.role.name} />
                    </div>
                    <div className="flex flex-col mb-5">
                        <label>Institution</label>
                        <input className="border-2 px-2 rounded-lg" disabled type="text" value={user && user.institution && user.institution.name} />
                    </div>
                    <div>
                        <button type="submit">Save</button>
                    </div>
                </form>

                <form onSubmit={(e) => {e.preventDefault(); handleChangePassword(e)}} className="flex flex-col gap-5">
                    <div class="text-slate-700 m-0 h-fit flex flex-row items-center shadow-lg rounded-full bg-white">
                        <input required className="rounded-l-full p-1 px-5 w-full" placeholder="Old Password" type={ oldPasswordVisible ? "text" : "password" } value={oldPassword} onChange={e => setOldPassword(e.target.value)} />
                        <button type="button" onMouseDown={() => setOldPasswordVisible(true)} onMouseUp={() => setOldPasswordVisible(false)} className="px-3"><span className="">{ !oldPasswordVisible ? <MdVisibility className="fill-black" /> : <MdVisibilityOff className="fill-black" /> }</span></button>
                    </div>
                    <div class="text-slate-700 m-0 h-fit flex flex-row items-center shadow-lg rounded-full bg-white">
                        <input required className="rounded-l-full p-1 px-5 w-full" placeholder="New Password" type={ passwordVisible ? "text" : "password" } value={password} onChange={e => setPassword(e.target.value)} />
                        <button type="button" onMouseDown={() => setPasswordVisible(true)} onMouseUp={() => setPasswordVisible(false)} className="px-3"><span className="">{ !passwordVisible ? <MdVisibility className="fill-black" /> : <MdVisibilityOff className="fill-black" /> }</span></button>
                    </div>
                    <div class="text-slate-700 m-0 h-fit flex flex-row items-center shadow-lg rounded-full bg-white">
                        <input required className="rounded-l-full p-1 px-5 w-full" placeholder="Repeat New Password" type={ confirmPasswordVisible ? "text" : "password" } value={confirmPassword} onChange={e => setConfirmPassword(e.target.value)} />
                        <button type="button" onMouseDown={() => setConfirmPasswordVisible(true)} onMouseUp={() => setConfirmPasswordVisible(false)} className="px-3"><span className="">{ !confirmPasswordVisible ? <MdVisibility className="fill-black" /> : <MdVisibilityOff className="fill-black" /> }</span></button>
                    </div>
                    {(error) ? <p className="text-red-500">We had some trouble getting to you. Please try again later</p> : ""}
                    <div className="flex flex-row justify-between items-center">
                        <button onClick={() => handleChangePassword()} type="submit" className="btn-cta">Reset My Password</button>
                        {(loading) ? <ReactLoading type={"spinningBubbles"} color={"#b7145a"} height={40} width={40} /> : ""}
                    </div>
                </form>

                <Modal 
                    className="OpenModal"
                    overlayClassName="OpenModalOverlay"
                    isOpen={success}
                    onRequestClose={() => {setSuccess(false); nav('/')}}
                    contentLabel="Edit Course"
                >
                    <div className='p-10 text-center flex flex-col h-full items-center justify-center'>
                        <MdCheckCircle className="text-green-500 text-6xl mb-5" />
                        <h1 className='mb-5 text-xl'>Success</h1>
                        <p>Your password has been changed</p>
                        <div className='flex flex-row justify-center gap-5 mt-5'>
                            <button onClick={() => {setSuccess(false); nav('/')}} className="btn-cta">Close</button>
                        </div>
                    </div>
                </Modal> 
            </div>
        </div>
    );
}

export default AccountSettings;