import { useTranslation } from "react-i18next";
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { environment } from '../environment';
import ReactLoading from 'react-loading';
import Messages from '../components/messenger/Messages';
import { timestampToHourMinute } from '../utilities/helpers';
import { MdAdd } from 'react-icons/md';
import Modal from 'react-modal';

const Messenger = () => {
    const [messages, setMessages] = useState([]);
    const [chats, setChats] = useState([]);
    const [selectedChat, setSelectedChat] = useState(null);
    const [loading, setLoading] = useState(true);
    const [rerender, doRerender] = useState(0);
    const [user, setUser] = useState({});
    const [userWantsToAddChat, setUserWantsToAddChat] = useState(false);

    const { t } = useTranslation();
    const nav = useNavigate();

    useEffect(() => {
        document.title = "Messenger - Puspakata";

        fetchChats();

        axios.get(environment.baseAPI + '/auth/me', {withCredentials: true, headers: {
            Authorization: "Bearer " + localStorage.getItem('authtoken')}
        }).then(res => {
            setUser(res.data.user);
        }).catch(error => {
            if (error.response.status === 401) {
                nav('/login');
            }
        });
    }, []);

    const fetchChats = () => {
        setLoading(true);
        axios.get(environment.baseAPI + '/messages/chats', {
            withCredentials: true, 
            headers: {
                Authorization: "Bearer " + localStorage.getItem('authtoken'),
                Accept: "application/json",
            }
        }).then(res => {
            res.data.sort((a, b) => {
                return new Date(b.last_message.created_at) - new Date(a.last_message.created_at);
            });

            setChats(res.data);
            setLoading(false);
        }).catch(error => {
            if (error.response.status === 401) {
                nav('/login');
            }
        });
    }

    useEffect(() => {
        if(chats.length > 0){
            setSelectedChat(chats[0]);
        }
    }, [chats]);

    const [searchLoading, setSearchLoading] = useState(false);
    const [searchUsers, setSearchUsers] = useState([]);

    const controller = new AbortController();

    const handleSearch = (query) => {
        if(searchLoading) {
            controller.abort();
            setSearchLoading(false);
        }

        setSearchLoading(true);

        axios.get(environment.baseAPI + '/users/search?q=' + query, {
            signal: controller.signal,
            withCredentials: true, 
            headers: {
                Authorization: "Bearer " + localStorage.getItem('authtoken'),
                Accept: 'application/json',
            }
        }).then(response => {
            setSearchUsers(response.data.filter(chatUser => {
                return (!chats.map(chat => chat.username).includes(chatUser.username)) && chatUser.username !== user.username;
            }));
            setSearchLoading(false);
        }).catch(error => {
            if (error && error.response && error.response.status === 401) {
                nav('/login');
            }
        });
    }

    // useEffect(() => {
    //     if (user.id === undefined) return;
    //     window.Echo.private('user.' + user.id)
    //         .listen('.new-message-was-sent', (e) => {
                
    //         });
    // }, [user, chats.length]);


    const handleAddChat = (id) => {
        axios.post(environment.baseAPI + '/messages/add', {oid: id}, {
            withCredentials: true, 
            headers: {
                Authorization: "Bearer " + localStorage.getItem('authtoken'),
                Accept: 'application/json',
            }
        }).then(response => {
            setUserWantsToAddChat(false);
            fetchChats();
        }).catch(error => {
            if (error && error.response && error.response.status === 401) {
                nav('/login');
            }
        });
    }
    
    return (
        <div className='text-left h-screen'>
            { (loading) ?
            <div className='w-full h-full flex flex-row justify-center items-center'> 
                <ReactLoading type={"spinningBubbles"} color={"#b7145a"} height={40} width={40} />
            </div>
            :
            <div className='h-full'>
                <div className='grid grid-cols-6 gap-3 h-full'>
                    <div className='col-span-2 p-5 bg-slate-100 dark:bg-slate-700 rounded-lg'>
                        <div className='flex flex-row justify-between items-center mb-5'>
                            <h1 className=''>Chats</h1>
                            <MdAdd className='cursor-pointer' onClick={() => setUserWantsToAddChat(true)} />
                            <Modal 
                                isOpen={userWantsToAddChat}
                                onRequestClose={() => setUserWantsToAddChat(false)}
                                className="OpenModal"
                                overlayClassName="OpenModalOverlay"
                            >
                                <div className='p-5'>
                                    <div className='flex flex-col gap-3'>
                                        <h1 className='text-left'>Add Chat</h1>
                                        <input type="text" className='text-black border-2 rounded-lg p-2'  onInput={(e) => handleSearch(e.target.value)} placeholder='Search for a user' />
                                    </div>
                                    { searchLoading ? 
                                    <div className="py-3 w-full flex justify-center">
                                        <ReactLoading type={"bars"} color={"#b7145a"} height={30} width={30} />
                                    </div>
                                    :
                                    <>
                                        { 
                                            (searchUsers.length == 0) ?
                                            <div className="w-full p-3 text-center">
                                                <p className="h-fit text-slate-500">{t('app.format.no_search_results')}</p>
                                            </div>
                                                :
                                            <>
                                                { searchUsers.length > 0 ? 
                                                <div className="">
                                                    { searchUsers.map(user => {
                                                        return (
                                                            <div className="w-full dark:hover:bg-slate-400 hover:bg-slate-200 transition-colors py-3 px-5">
                                                                <div onClick={() => handleAddChat(user.oid)} className="cursor-pointer">
                                                                    <div className="flex flex-row items-center gap-5">
                                                                        <div className="">
                                                                            { user.avatar != null ?
                                                                                <img id="pfp" src={user.avatar} width="40" alt="" className="rounded-full"
                                                                                    data-dz-thumbnail="data-dz-thumbnail" />
                                                                            :
                                                                                <img id="pfp"
                                                                                    src={"https://ui-avatars.com/api/?background=D6D6D6&bold=true&name=" + user.name} className="rounded-full"
                                                                                    width="40" alt="" data-dz-thumbnail="data-dz-thumbnail" />
                                                                            }
                                                                        </div>
                                                                        <div>
                                                                            <h1 className="text-md">{user.name}</h1>
                                                                            <p className="font-light text-sm">@{user.username}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    }    
                                                    )}
                                                </div>
                                                :
                                                ""
                                                }
                                            </>
                                        }
                                    </>
                                    }
                                </div>
                            </Modal>
                        </div>
                        <ul className='h-[calc(100vh-6rem)] flex flex-col overflow-y-scroll nobar'>
                            {chats.map(chat => {
                                return <li key={chat.id} onClick={() => setSelectedChat(chat)} className={'p-3 transition-colors rounded-xl cursor-pointer hover:bg-slate-200 dark:hover:bg-slate-600 ' + ((selectedChat && (chat.id == selectedChat.id)) ? "bg-slate-200 dark:bg-slate-600" : "" )}>
                                    <div>
                                        <div className="flex flex-row gap-3 text-left items-start w-full">
                                            <div className="h-fit flex-shrink-0">
                                                { chat && chat.avatar != null ?
                                                    <img id="pfp" src={chat.avatar} alt="" className="rounded-full pfp w-[30px] h-[30px]"
                                                        data-dz-thumbnail="data-dz-thumbnail" />
                                                :
                                                    <img id="pfp"
                                                        src={chat &&  "https://ui-avatars.com/api/?background=D6D6D6&bold=true&name=" + chat.name} className="rounded-full pfp"
                                                        width="30" alt="" data-dz-thumbnail="data-dz-thumbnail" />
                                                }
                                            </div>
                                            <div className="sm:w-full w-0 sm:block hidden overflow-clip">
                                                <p className="text-sm m-0 line-clamp-1 w-fit">{chat && chat.name.split(' ').slice(0,2).join(' ')}</p>
                                                <div className='flex flex-col justify-between w-full'>
                                                    <p className="text-xs font-light m-0 flex-shrink line-clamp-1 w-max">{(chat.last_message && chat.last_message.sender_id == user.id) ? "(You)" : ""} {chat.last_message && chat.last_message.message}</p>
                                                    <p className="text-xs font-light m-0 text-slate-500 flex-shrink-0">{chat.last_message && timestampToHourMinute(chat.last_message.created_at)}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            })}
                        </ul>
                    </div>
                    <div className='col-span-4 p-5 bg-slate-100 dark:bg-slate-700 rounded-lg max-h-full'>
                        {/* <h1 className='mb-5'>Messages</h1> */}
                        {
                        selectedChat &&
                        <div className='pb-3'>
                            <div className="flex flex-row gap-3 text-left items-start w-full">
                                <div className="h-fit flex-shrink-0">
                                    { selectedChat && selectedChat.avatar != null ?
                                        <img id="pfp" src={selectedChat.avatar} alt="" className="rounded-full pfp w-[30px] h-[30px]"
                                            data-dz-thumbnail="data-dz-thumbnail" />
                                    :
                                        <img id="pfp"
                                            src={selectedChat &&  "https://ui-avatars.com/api/?background=D6D6D6&bold=true&name=" + selectedChat.name} className="rounded-full pfp"
                                            width="30" alt="" data-dz-thumbnail="data-dz-thumbnail" />
                                    }
                                </div>
                                <div className="overflow-clip">
                                    <p className="text-sm m-0 line-clamp-1 w-fit">{selectedChat && selectedChat.name.split(' ').slice(0,2).join(' ')}</p>
                                    <p className='text-xs text-slate-500'>@{selectedChat.username}</p>
                                </div>
                            </div>
                        </div>
                        }
                        {selectedChat && <Messages key={selectedChat.id} rerender={rerender} chat={selectedChat} className="h-full" />}
                    </div>
                </div>
            </div>
            }
        </div>
    );
}

export default Messenger;